import projectData from '../../../Data';
import copyOfArrayOrObject from '../../../Functions/copyOfArrayOrObject';

const getUpdatedQuoteDataWithMoodboardImages = (moodboardData) => {
  const copyOfQuoteData = copyOfArrayOrObject(projectData.quote.quoteData);
  copyOfQuoteData.forEach((quoteData) => {
    quoteData.quoteItems.forEach((quoteItem) => {
      quoteItem.items.forEach((item) => {
        item.images = getMoodBoardItemImages(
          quoteData.unit.fields['Item Name'],
          quoteItem.room.description,
          item.fields['Item Name'],
          moodboardData
        );
      });
    });
  });
  return copyOfQuoteData;
};

const getMoodBoardItemImages = (
  unitName,
  roomName,
  itemName,
  moodboardData
) => {
  let itemData = [];
  if (moodboardData[unitName] && moodboardData[unitName][roomName]) {
    let activeTab = moodboardData[unitName][roomName].filter(
      (tab) => tab.activeTab === true
    );
    const roomMoodBoardItems = activeTab[0].moodboard;
    roomMoodBoardItems.forEach((item, index) => {
      if (
        item.fulhausCategory.trim() === itemName.trim() ||
        (item.mappedFulhausCategory &&
          item.mappedFulhausCategory.trim() === itemName.trim())
      ) {
        let imageUrls = [];
        imageUrls.push(item.displayedImage);
        itemData.push({
          urls: imageUrls,
          priceMAP: item.MAP,
          imageName: item.itemName,
        });
      }
    });
  }
  return itemData;
};

export default getUpdatedQuoteDataWithMoodboardImages;
