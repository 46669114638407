import React, { useState, useEffect } from "react";
import "./ProjectsPage.css";
import NavBar from "../Common/NavBar/NavBar";
import Footer from "../Common/Footer/Footer";
import fetchUtils from "../../Functions/fetchUtils";
import { connect } from "react-redux";
import { BlockLoading } from "react-loadingg";
import setProject from "../../Functions/setProject";
import setQuote from "../../Functions/setQuote";
import setDesignStyles from "../../Functions/setDesignStyles";
import data from ".././../Data";
import setProjectFilesMetaData from "../../Functions/setProjectFilesMetaData";
import setDesignElementCategories from "../../Functions/setDesignElementCategories";
import setProjectNotes from "../../Functions/setProjectNotes";
import setAirtableCategory from "../../Functions/setAirtableCategory";
import setAirtableProjects from "../../Functions/setAirtableProjects";

const UnconnectedProjectsPage = (props) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);

    // const pathArray = props.location.pathname.split("/");
    // const pathName = pathArray[pathArray.length - 1];

    // if (pathName === "projects" || pathName === "") {
    //   setIsLoading(false);
    //   return;
    // }

    const urlSearchParams = new URLSearchParams(window.location.search);
    const opportunityID = urlSearchParams.get('opportunityID');

    if (!opportunityID) {
      setIsLoading(false);
      return;
    }

    //change pathname to opportunity for the new SF integration
    const pathName = opportunityID;

    const initializeData = async () => {
      const projectResponse = await getProject(pathName);
      if (!projectResponse) {
        return;
      }

      const quoteResponse = await getQuote(pathName);
      if (!quoteResponse) {
        return;
      }

      const designDataResponse = await getDesignData(
        data.quote.quoteID.quoteID
      );

      if (!designDataResponse) {
        props.dispatch({
          type: "initialize-moodboardData",
          value: {},
          revisionNum: 1,
        });
      }

      const designStylesResponse = await getDesignStyles();
      if (!designStylesResponse) {
        return;
      }

      // const getProjectFilesResponse = await getProjectFiles();
      // if (!getProjectFilesResponse) {
      //   return;
      // }

      // const getProjectNotesResponse = await getProjectNotes();
      // if (!getProjectNotesResponse) {
      //   return;
      // }

      const getDesignElementCategoriesResponse =
        await getDesignElementCategories();
      if (!getDesignElementCategoriesResponse) {
        return;
      }

      if (
        // projectResponse.Currency === "EUR" ||
        // projectResponse.Currency === "GBP"
             projectResponse?.CurrencyIsoCode === "EUR" ||
        projectResponse?.CurrencyIsoCode === "GBP"
      ) {
        const airtableCategoriesResponse = await getAirtableEUCategories();
        const airtableProjectsResponse = await getAirtableEUProjects();
        if (!airtableCategoriesResponse && !airtableProjectsResponse) {
          return;
        }
      } else {
        const airtableCategoriesResponse = await getAirtableCategories();
        const airtableProjectsResponse = await getAirtableProjects();
        if (!airtableCategoriesResponse && !airtableProjectsResponse) {
          return;
        }
      }

      props.history.push("/units");
    };

    initializeData();
  }, []);

  const getAirtableEUProjects = async () => {
    const allAirtableProjects = await fetchUtils(
      "/airtable/projects/eu",
      "GET"
    );
    if (allAirtableProjects.success) {
      setAirtableProjects(allAirtableProjects.data);
      return true;
    } else {
      return false;
    }
  };

  const getAirtableProjects = async () => {
    const allAirtableProjects = await fetchUtils(
      "/airtable/projects/na",
      "GET"
    );

    if (allAirtableProjects.success) {
      // console.log(allAirtableProjects.data);
      setAirtableProjects(allAirtableProjects.data);
      return true;
    } else {
      // console.log(allAirtableProjects.message);
      return false;
    }
  };

  const getAirtableCategories = async () => {
    const categories = await fetchUtils("/airtable/items/categories", "GET");

    if (categories.success) {
      setAirtableCategory(categories.data);
      return true;
    } else {
      // console.log(categories.message);
      return false;
    }
  };
  const getAirtableEUCategories = async () => {
    const categories = await fetchUtils("/airtable/items/eu/categories", "GET");

    if (categories.success) {
      setAirtableCategory(categories.data);
      return true;
    } else {
      // console.log(categories.message);
      return false;
    }
  };

  // const getProject = async (projectName) => {
  //   const project = await fetchUtils(
  //     "/pipedrive/projects/" + projectName,
  //     "GET"
  //   );
  //   if (project.success)
  //   {
  //     console.log(project)
  //     setProject(project.project);
  //     return project.project;
  //   } else {
  //     console.log(project.message);
  //     return false;
  //   }
  // };

    const getProject = async (sfOpportunityID) => {
    const project = await fetchUtils(
     `/projects/sf/opportunity/${sfOpportunityID}`,
      "GET"
    );
    if (project.success)
    {
      setProject(project.opportunity);
      return project.opportunity;
    } else {
      // console.log(project.message);
      return false;
    }
  };

  // const getQuote = async (projectName) => {
  //   const quote = await fetchUtils("/quotes/project/" + projectName, "GET");
  //   if (quote.success) {
  //     setQuote(quote.data);
  //     return true;
  //   } else {
  //     console.log(quote.message);
  //     return false;
  //   }
  // };

    const getQuote = async (sfOpportunityID) => {
    const quote = await fetchUtils(`/quotes/sf/project/${sfOpportunityID}`, "GET");
    if (quote.success) {
      setQuote(quote.data);
      return true;
    } else {
      // console.log(quote.message);
      return false;
    }
  };


  const getDesignData = async (quoteID) => {
    const designData = await fetchUtils(`/moodboard/${quoteID}`, "GET");
    if (designData.success) {
      const moodboardData = designData.moodboard.moodboardData;
      props.dispatch({
        type: "initialize-moodboardData",
        value: moodboardData[moodboardData.length - 1].data,
        revisionNum: 1,
      });

      return true;
    } else {
      // console.log(designData.message);
      return false;
    }
  };

  const getDesignStyles = async () => {
    const designStyles = await fetchUtils("/airtable/design-styles", "GET");
    if (designStyles.success) {
      setDesignStyles(designStyles.data);
      return true;
    } else {
      // console.log(designStyles.message);
      return false;
    }
  };

  // const getProjectFiles = async () => {
  //   const projectFilesMetaData = await fetchUtils(
  //     "/pipedrive/files/" + data.project.id,
  //     "GET"
  //   );
  //   if (projectFilesMetaData.success) {
  //     setProjectFilesMetaData(projectFilesMetaData.files);
  //     return true;
  //   } else {
  //     console.log(projectFilesMetaData.message);
  //     return false;
  //   }
  // };

  // const getProjectNotes = async () => {
  //   const projectNotes = await fetchUtils("/notes/" + data.project.id, "GET");
  //   if (projectNotes.success) {
  //     setProjectNotes(projectNotes.data);
  //     return true;
  //   } else {
  //     console.log(projectNotes.message);
  //     return false;
  //   }
  // };

  const getDesignElementCategories = async () => {
    let designElementCategories = await fetchUtils(
      "/design-element-categories",
      "GET"
    );
    if (designElementCategories.success) {
      setDesignElementCategories(designElementCategories.data);
      return true;
    } else {
      // console.log(designElementCategories.message);
      return false;
    }
  };

  return (
    <div className="projects-page-wrapper">
      <NavBar />
      {isLoading ? (
        <div className="projects-page-body">
          <BlockLoading color="#b8c5d3" />
        </div>
      ) : (
        <div className="projects-page-body">
          <h1>Your Projects</h1>
        </div>
      )}
      <Footer />
    </div>
  );
};

let mapStateToProps = (state) => {
  return {
    moodboardData: state.moodboardData,
  };
};
let ProjectsPage = connect(mapStateToProps)(UnconnectedProjectsPage);
export default ProjectsPage;
