import React, { useState, useEffect } from 'react';
import './Filters.css';
import PriceFilter from './PriceFilter/PriceFilter';
import Line from '../../../Common/Line';
import RadiusFilter from './RadiusFilter/RadiusFilter';
import DimensionsFilter from './DimensionsFilter/DimensionsFilter';
import WeightFilter from './WeightFilter/WeightFilter';
import ColorFilter from './ColorFilter/ColorFilter';
import ImageFilter from './ImageFilter/ImageFilter';
import Button from '../../../Common/Button';
import SearchBox from './SearchBox/SearchBox';
import VendorFilter from './VendorFilter/VendorFilter';
import copyOfArrayOrObject from '../../../../Functions/copyOfArrayOrObject';
import { FiX } from 'react-icons/fi';
import DatabaseFilter from './DatabaseFilter/DatabaseFilter';

const Filters = (props) => {
  const [filterParams, setFilterParams] = useState({});

  useEffect(() => {
    setFilterParams(props.filterParams);
  }, [props]);

  const handleFilterParams = (key, value) => {
    let copyOfFilterParams = copyOfArrayOrObject(filterParams);
    if (key === 'price' || key === 'dimensions') {
      copyOfFilterParams = { ...filterParams, ...value };
      setFilterParams(copyOfFilterParams);
      return;
    }

    if (key === 'vendorName') {
      copyOfFilterParams = { ...filterParams, ...value };
      setFilterParams(copyOfFilterParams);
      return;
    }
    if (key === 'database') {
      copyOfFilterParams = { ...filterParams, ...value };
      setFilterParams(copyOfFilterParams);
      return;
    }

    if (key === 'similarImageURL') {
      copyOfFilterParams[key] = value;
      copyOfFilterParams.color = null;
      setFilterParams(copyOfFilterParams);
      return;
    }

    if (key === 'color') {
      copyOfFilterParams[key] = value;
      copyOfFilterParams.similarImageURL = null;
      setFilterParams(copyOfFilterParams);
      return;
    }

    copyOfFilterParams[key] = value;
    setFilterParams(copyOfFilterParams);
  };

  const handleFilterSearch = (currentFilterParams) => {
    props.handleFilterSearch(currentFilterParams);
  };

  const handleClearFilter = () => {
    props.handleClearFilter();
  };

  return (
    <div
      className='filters-wrapper'
      style={props.isSortOption ? { padding: '0' } : {}}
    >
      {!props.isSortOption && (
        <React.Fragment>
          <div className='filters-header'>
            <div className='filters-header-top'>
              <h4>Filters</h4>
              <FiX
                className='filters-close-icon'
                onClick={() => props.handleCloseFilter()}
              />
            </div>

            <SearchBox
              searchParams={filterParams.itemName}
              handleSearchInput={(v) => handleFilterParams('itemName', v)}
            />
          </div>
          {<Line width='100%' height='1px' color='#f2f2f2' />}
        </React.Fragment>
      )}
      <div
        className='filters-body'
        style={props.isSortOption ? { paddingBottom: '0' } : {}}
      >
        {!props.isSortOption && (
          <React.Fragment>
            {/** 
          <DatabaseFilter
              selectedDatabase={filterParams.database}
              handleSelectDatabase={(v) => handleFilterParams('database', v)}
            />
          */}
            <VendorFilter
              vendors={props.vendors}
              selectedVendor={filterParams.vendorName}
              handleSelectVendorName={(v) =>
                handleFilterParams('vendorName', v)
              }
            />
            {<Line width='100%' height='1px' color='#f2f2f2' />}
            <PriceFilter
              priceRange={{
                min: filterParams.priceMin,
                max: filterParams.priceMax,
              }}
              rangeValueLabel={props.rangeValueLabel}
              rangeSize={props.rangeSize}
              handlePriceRange={(v) => handleFilterParams('price', v)}
            />
            {<Line width='100%' height='1px' color='#f2f2f2' />}
            <RadiusFilter
              rangeValueLabel={'mi'}
              radius={filterParams.radius}
              handleRadiusRange={(v) => handleFilterParams('radius', v)}
            />
            {<Line width='100%' height='1px' color='#f2f2f2' />}
            <DimensionsFilter
              rangeValueLabel={'in'}
              dimensions={{
                width: filterParams.width,
                height: filterParams.height,
                length: filterParams.length,
              }}
              handleDimensions={(v) => handleFilterParams('dimensions', v)}
            />
            {<Line width='100%' height='1px' color='#f2f2f2' />}
            <WeightFilter
              rangeValueLabel={'lb'}
              weight={filterParams.weight}
              handleWeight={(v) => handleFilterParams('weight', v)}
            />
          </React.Fragment>
        )}
        {props.isSortOption && (
          <React.Fragment>
            {props.sortOption === 'color' && (
              <ColorFilter
                color={filterParams.color}
                handleColorSelect={(v) => handleFilterParams('color', v)}
              />
            )}

            {props.sortOption === 'similarImage' && (
              <ImageFilter
                similarImageURL={filterParams.similarImageURL}
                handleSimilarImageURL={(v) =>
                  handleFilterParams('similarImageURL', v)
                }
              />
            )}
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingBottom: 'calc(5px + 0.5vw)',
              }}
            >
              <Button
                name='sort'
                background='#242627'
                borderRadius='5px'
                width='65%'
                enabled={true}
                height='calc(20px + 1vw)'
                padding='0 calc(10px + 1vw)'
                onClick={() => handleFilterSearch(filterParams)}
                color='#FFFFFF'
                label='Submit'
                fontSize='calc(8px + 0.5vw)'
              />
            </div>
          </React.Fragment>
        )}
        {!props.isSortOption && (
          <div className='filters-search-button'>
            <div style={{ width: '30%' }}>
              <Button
                name='filter'
                background='none'
                borderRadius='5px'
                width='100%'
                enabled={true}
                height='calc(20px + 2vw)'
                padding='0'
                onClick={() => handleClearFilter()}
                color='#242627'
                label='Clear Filters'
                fontSize='calc(5px + 0.5vw)'
              />
            </div>
            <div style={{ width: '65%' }}>
              <Button
                name='filter'
                background='#242627'
                borderRadius='5px'
                width='100%'
                enabled={true}
                height='calc(20px + 2vw)'
                padding='0 calc(10px + 1vw)'
                onClick={() => handleFilterSearch(filterParams)}
                color='#FFFFFF'
                label='Filter'
                fontSize='calc(8px + 0.5vw)'
              />
            </div>
          </div>
        )}
      </div>
      {props.isLoading && <div className='filters-loading-overlay'></div>}
    </div>
  );
};

export default Filters;
