import React from 'react';
import { ChromePicker } from 'react-color';

export default function BackgroundChange(props) {
  const [hex, setHex] = React.useState('#ffffff');

  const styles = {
    multiInputsContainer: {
      marginTop: '5px',
      border: '1px solide white',
    },
  };

  const handleColorChange = (color) => {
    setHex(color.hex);
    props.handleBKGChange(color);
  };

  return (
    <div style={styles.multiInputsContainer}>
      <ChromePicker color={hex} onChangeComplete={handleColorChange} />
    </div>
  );
}
