const numberToCurrencyFormatter = (amount = 0, currency = "USD") => {
  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
  });

  return formatter.format(amount);
};

export default numberToCurrencyFormatter;
