import React, { useEffect, useState } from 'react';
import fetchUtils from '../../../../Functions/fetchUtils';
import { DisappearedLoading } from 'react-loadingg';
import { SiMongodb } from 'react-icons/si';
import data from '../../../../Data';
import AirtableProductCard from './AirtableProductsCard';
import AirtableFilters from './AirtableFilters/AirtableFilters';
import { FiFilter } from 'react-icons/fi';

const AirtableProducts = (props) => {
  const [ignoreCurrency, setIgnorCurrency] = useState(true);
  const [airtableProducts, setAirtableProducts] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  // const projectCurrency = data.project.Currency;
    const projectCurrency = data.project?.CurrencyIsoCode;

  useEffect(() => {
    // if (data.project.Currency === 'EUR' || data.project.Currency === 'GBP')
    // {
       if (data.project?.CurrencyIsoCode === 'EUR' || data.project?.CurrencyIsoCode === 'GBP') {
      getAirtableProductsEU();
    } else {
      getAirtableProducts();
    }
  }, []);

  const getAirtableProducts = async () => {
    setIsloading(true);
    let data = {
      projectCurrency: projectCurrency,
      ignoreCur: ignoreCurrency,
      category: props.selectedItem,
    };

    const payload = await fetchUtils('/airtable/products/getAll', 'POST', data);
    if (payload.success) {
      setIsloading(false);
      setAirtableProducts(payload.data);
    }
  };
  const getAirtableProductsEU = async () => {
    setIsloading(true);
    let data = {
      projectCurrency: projectCurrency,
      ignoreCur: ignoreCurrency,
      category: props.selectedItem,
    };

    const payload = await fetchUtils(
      '/airtable/products/eu/getAll',
      'POST',
      data
    );
    // console.log('EU-', payload);
    if (payload.success) {
      setIsloading(false);
      setAirtableProducts(payload.data);
    }
  };

  const handleSearch = async (search) => {
    setAirtableProducts([]);
    setIsloading(true);
    let _data = {
      projectCurrency: projectCurrency,
      ignoreCur: ignoreCurrency,
      category: props.selectedItem,
    };
    if (search.length > 0) {
      const payload = await fetchUtils(
        `/airtable/products/${search}`,
        'POST',
        _data
      );
      // console.log('SEARCH', payload);
      if (payload.success) {
        setIsloading(false);
        setShowFilters(false);
        setAirtableProducts(payload.data);
      }
    }
  };

  const handleProjectSearch = async (search) => {
    setAirtableProducts([]);
    setIsloading(true);
    let selectedProject = null;
    let allProjects = data.airtableProjects;
    // console.log(allProjects)
    let index = allProjects.findIndex(
      (project) =>
        project.fields['Listing ID'] &&
        project.fields['Listing ID']
          .toUpperCase()
          .includes(search.toUpperCase())
    );
    selectedProject = allProjects[index];
    // console.log('SELECTED PROJECT', selectedProject);
    let _data = {
      projectCurrency: projectCurrency,
      ignoreCur: ignoreCurrency,
      category: props.selectedItem,
    };
    if (selectedProject) {
      const payload = await fetchUtils(
        `/airtable/products/search/${selectedProject.fields['Listing ID']}`,
        'POST',
        _data
      );
      // console.log('Project Search', payload);
      if (payload.success) {
        setIsloading(false);
        setShowFilters(false);
        setAirtableProducts(payload.data);
      }
    } else {
      setIsloading(false);
    }
  };

  const handleEUProjectSearch = async (search) => {
    setAirtableProducts([]);
    setIsloading(true);
    let selectedProject = null;
    let allProjects = data.airtableProjects;
    // console.log('ALL EU PROJ', allProjects);
    let index = allProjects.findIndex(
      (project) =>
        project.fields['Name'] &&
        project.fields['Name'].toUpperCase().includes(search.toUpperCase())
    );
    selectedProject = allProjects[index];
    // console.log('SELECTED PROJECT', selectedProject);
    let _data = {
      projectCurrency: projectCurrency,
      ignoreCur: ignoreCurrency,
      category: props.selectedItem,
    };
    if (selectedProject) {
      const payload = await fetchUtils(
        `/airtable/products/eu/search/${selectedProject.fields['Name']}`,
        'POST',
        _data
      );
      // console.log('Project Search', payload);
      if (payload.success) {
        setIsloading(false);
        setShowFilters(false);
        setAirtableProducts(payload.data);
      }
    } else {
      setIsloading(false);
    }
  };

  return (
    <>
      {showFilters && (
        <section className='airtable-products-filters'>
          <AirtableFilters
            handleCloseFilter={() => setShowFilters(false)}
            handleSearch={(search, type) => {
              type === 'projectSearch'
                // ? data.project.Currency === 'EUR' ||
                //   data.project.Currency === 'GBP'
                  ? data.project?.CurrencyIsoCode === 'EUR' ||
                  data.project?.CurrencyIsoCode === 'GBP'
                  ? handleEUProjectSearch(search)
                  : handleProjectSearch(search)
                : handleSearch(search);
            }}
          />
        </section>
      )}
      <div className='products-wrapper'>
        <section
          className='products-display'
          style={{ width: showFilters ? '75%' : '100%' }}
        >
          <div className='products-display-header'>
            <h1>{props.selectedItem}</h1>
            <div className='products-display-header-right-container'>
              <div
                className='products-display-airtable'
                onClick={() => props.setShowAirtableProducts()}
              >
                <SiMongodb className='products-display-airtable-icon' />
              </div>
            </div>
          </div>
          <div className='products-display-filter-sort-row'>
            <div className='products-display-filters'>
              <div
                className='products-display-filters-desc'
                onClick={() => setShowFilters(true)}
              >
                <FiFilter className='products-display-filters-desc-icon' />
                {!showFilters && <h6>Filters</h6>}
              </div>
            </div>
          </div>
          <div className='products-display-body'>
            {isLoading && !airtableProducts.length > 0 ? (
              <div
                className='products-display-body'
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100% ',
                  justifyContent: 'center',
                }}
              >
                <DisappearedLoading color='#ff4e24' />
              </div>
            ) : (
              <>
                <div className='products-display-body'>
                  {airtableProducts.map((product, index) => {
                    return (
                      <AirtableProductCard
                        key={index}
                        category={props.selectedItem}
                        product={product}
                        handleSelectProduct={(p, roomType) => {
                          props.handleSelectProduct(p, roomType);
                        }}
                        detailsModal={(item) => {
                          props.detailsModal(item);
                        }}
                      />
                    );
                  })}
                </div>
              </>
            )}
          </div>
        </section>
      </div>
    </>
  );
};
export default AirtableProducts;
