const getMeta = (url) => {
  return new Promise((resolve, reject) => {
    var img = new Image();
    img.addEventListener('load', function () {
      resolve({
        width:
          (this.naturalWidth * 200) / (this.naturalWidth + this.naturalHeight),
        height:
          (this.naturalHeight * 200) / (this.naturalWidth + this.naturalHeight),
      });
    });
    img.src = url;
  });
};

const getImgArray = (imgArray) => {
  let tmpArray = [];
  return new Promise((resolve, reject) => {
    for (let index = 0; index < imgArray.length; index++) {
      tmpArray.push(imgArray[index].url);
    }
    resolve(tmpArray);
  });
};

export { getMeta, getImgArray };
