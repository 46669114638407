import React, { useEffect, useState } from "react";
import { Route, BrowserRouter, Switch, Redirect } from "react-router-dom";
import PageNotFound from "./Components/PageNotFound/PageNotFound";
// import fetchUtils from "./Functions/fetchUtils";
import { BlockLoading } from "react-loadingg";
// import openURL from "./Functions/openURL";
import ProjectsPage from "./Components/ProjectsPage/ProjectsPage";
import UnitsPage from "./Components/UnitsPage/UnitsPage";
import DesignPage from "./Components/DesignPage/DesignPage";
import { connect } from "react-redux";

const UnconnectedApp = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // useEffect(() => {
  //   setIsLoading(true);

  //   if (!props.user) {
  //     const checkUserLoginStatus = async () => {
  //       let response = await fetchUtils("/users/status", "GET", "");

  //       if (response.success) {
  //         props.dispatch({
  //           type: "set-user",
  //           value: response.data,
  //         });
  //         setIsAuthenticated(true);
  //         setIsLoading(false);
  //       } else {
  //         console.log(response.message);
  //         // openURL("https://apps.fulhaus.com");
  //         openURL("http://localhost:3006");
  //       }
  //     };

  //     checkUserLoginStatus();
  //     return;
  //   }
  // }, []);

  const authenticatedRoute = (page) => {
    if (!isAuthenticated) {
      // openURL("https://apps.fulhaus.com");
      // openURL("http://localhost:3006");
    }
    return page;
  };

  return isLoading ? (
    <BlockLoading color="#B8C5D3" />
  ) : (
    <BrowserRouter>
      <Switch>
        <Route
          exact={true}
          path="/"
          component={authenticatedRoute(DesignPage)}
        />
        <Route
          exact={true}
          path="/projects"
          component={authenticatedRoute(ProjectsPage)}
        />
        <Route
          exact={true}
          path="/projects/:project"
          component={authenticatedRoute(ProjectsPage)}
        />
        <Route
          exact={true}
          path="/units"
          component={authenticatedRoute(UnitsPage)}
        />
        <Route exact={true} path="/404" component={PageNotFound} />
        <Redirect to="/404" />
      </Switch>
    </BrowserRouter>
  );
};

let App = connect()(UnconnectedApp);
export default App;
