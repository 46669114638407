import React, { useState } from "react";
import "./UnitsPage.css";
import NavBar from "../Common/NavBar/NavBar";
import { connect } from "react-redux";
import Footer from "../Common/Footer/Footer";
import data from "../../Data";
import numberToCurrencyFormatter from "../../Functions/numberToCurrencyFormatter";
import UnitCard from "./UnitCard/UnitCard";
import { v4 as uuidv4 } from "uuid";
import PageModal from "../Common/PageModal";
import RoomsModal from "../RoomsModal/RoomsModal";
import { useEffect } from "react";

const UnconnectedUnitsPage = (props) => {
  const [showRooms, setShowRooms] = useState(false);
  const [selectedUnitData, setSelectedUnitData] = useState(null);

  const handleSelectedUnit = (index) => {
    setSelectedUnitData(data.quote.quoteData[index]);
    props.dispatch({
      type: "set-activeUnit",
      value: data.quote.quoteData[index],
    });
    setShowRooms(true);
  };

  const handleSelectedRoom = (room) => {
    props.dispatch({
      type: "set-activeRoom",
      value: room,
    });
    props.history.push("/");
  };

  return (
    <React.Fragment>
      <div className="units-page-wrapper">
        <NavBar
          // projectTitle={data.project.title}
           projectTitle={data.project?.Name}
          // projectAddress={data.project["project address"]}
          projectAddress={data.project?.address}
          projectBudget={numberToCurrencyFormatter(
            // data.project.value,
            // data.project.currency
             data.project?.Amount,
            data.project?.CurrencyIsoCode
          )}
        />
        <div className="units-page-body">
          <section className="units-page-body-header">
            <h1>Select Unit</h1>
            <p>Select any unit start or continue with the design</p>
          </section>

          <section className="units-page-body-units">
            <div className="units-page-body-units-fixed">
              {data.quote.quoteData
                .map((quoteData) => quoteData.unit)
                .map((unit, index) => {
                  return (
                    <div
                      key={uuidv4()}
                      className="units-page-body-unit-card-container"
                    >
                      <UnitCard
                        key={uuidv4()}
                        unitName={unit.fields["Item Name"]}
                        unitDescription={unit.fields["SKU"]}
                        handleSelectedUnit={() => handleSelectedUnit(index)}
                        unitData={unit}
                      />
                    </div>
                  );
                })}
            </div>
          </section>
        </div>
        <Footer />
      </div>
      {selectedUnitData && (
        <PageModal
          visible={showRooms}
          component={
            <RoomsModal
              rooms={selectedUnitData.roomList}
              handleSelectedRoom={(r) => handleSelectedRoom(r)}
            />
          }
          borderRadius="5px"
          padding="calc(20px + 2vw)"
          onClose={() => setShowRooms(false)}
        />
      )}
    </React.Fragment>
  );
};

let mapStateToProps = (state) => {
  return {
    activeUnit: state.activeUnit,
  };
};
let UnitsPage = connect(mapStateToProps)(UnconnectedUnitsPage);
export default UnitsPage;

//              The size of the unit to design, like studio, 1 bed, 2 bed, hotel,
// etc... This will help organize your designs and serve as a
// reference when you view and carry out the design
