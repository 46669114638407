import React, { useEffect, useState } from 'react';
import './NewProductCard.css';
import Button from '../../../Common/Button';
import { connect } from 'react-redux';
import Gap from '../../../Common/Gap';
import numberToCurrencyFormatter from '../../../../Functions/numberToCurrencyFormatter';
import { CgClipboard, CgInfo } from 'react-icons/cg';
import AddRoomMenu from './AddRoomMenu/AddRoomMenu';
import OutsideClickHandler from 'react-outside-click-handler';

const UnconnectedNewProductCard = (props) => {
  const [restockWithinMonth, setRestockWithinMonth] = useState(0);
  const [showAddRoomMenu, setShowAddRoomMenu] = useState(false);

  useEffect(() => {
    if (props.product && props.product.stockQty <= 0) {
      const dateNow = new Date();
      const restockDate = new Date(props.product.restockDate);
      const diffTime = Math.abs(restockDate - dateNow);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      if (diffDays <= 30 && restockDate > dateNow) {
        setRestockWithinMonth(diffDays);
      }
    }
  }, []);

  return (
    <div className='newProduct-card-wrapper'>
      <div className='newProduct-card-wrapper-container'>
        <div
          className='newProduct-card-product-image'
          style={{ backgroundImage: 'url(' + props.product.imageURLs[0] + ')' }}
        >
          <div
            className='newProduct-qty-wrapper'
            style={{
              backgroundColor: props.product.stockQty
                ? '#219653'
                : restockWithinMonth
                ? 'blue'
                : '#EB5757',
            }}
          >
            <a className='newProduct-qty'>
              {props.product.stockQty > 0 ? props.product.stockQty : '0'}
            </a>
          </div>
          <OutsideClickHandler
            onOutsideClick={() => {
              setShowAddRoomMenu(false);
            }}
          >
            <AddRoomMenu
              showMenu={showAddRoomMenu}
              activeUnit={props.activeUnit}
            />
          </OutsideClickHandler>
        </div>
        <div
          className={
            showAddRoomMenu
              ? 'newProduct-card-wrapper-container-overlay overlay-show'
              : 'newProduct-card-wrapper-container-overlay'
          }
        >
          <div className='newProduct-card-wrapper-container-overlay-top'>
            <h5>
              {props.product.itemName.length > 25
                ? props.product.itemName.substring(0, 25) + '...'
                : props.product.itemName}
            </h5>
            <p>{props.product.dimension}</p>
            <h2>
              {numberToCurrencyFormatter(
                props.product.MAP,
                props.product.tradeCurrency
              )}
            </h2>
          </div>
          <div className='newProduct-card-wrapper-container-overlay-bottom'>
            <Button
              name='select'
              prefixIconComponent={<CgClipboard />}
              width='100%'
              enabled={!showAddRoomMenu}
              height='calc(20px + 1vw)'
              padding='0'
              onClick={() =>
                // props.handleSelectProduct(
                //   props.product,
                //   props.activeRoom.description
                // )
                setShowAddRoomMenu(true)
              }
              color='#ffffff'
              label='Add to Room...'
              display='flex'
              fontSize='calc(8px + 0.5vw)'
              textAlign='left'
              suffixIconMargin='0 15px 0 0'
            />
            <Gap value='calc(2px + 0.2vw)' />
            <Button
              name='details'
              prefixIconComponent={<CgInfo />}
              width='100%'
              enabled={!showAddRoomMenu}
              height='calc(20px + 1vw)'
              padding='0'
              display='flex'
              onClick={() => props.detailsModal(props.product)}
              color='#ffffff'
              label='Details'
              fontSize='calc(8px + 0.5vw)'
              textAlign='left'
              suffixIconMargin='0 15px 0 0'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

let mapStateToProps = (state) => {
  return {
    activeRoom: state.activeRoom,
    activeUnit: state.activeUnit,
  };
};
let NewProductCard = connect(mapStateToProps)(UnconnectedNewProductCard);
export default NewProductCard;
