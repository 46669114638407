import React, { useState, useEffect } from "react";
import "./ViewBox.css";
import { FiMinus } from "react-icons/fi";
import { FiMaximize } from "react-icons/fi";
import { FiX } from "react-icons/fi";
import { FiMinimize } from "react-icons/fi";
import Gap from "../Gap";

const ViewBox = (props) => {
  const [viewBoxAction, setViewBoxAction] = useState(null);

  useEffect(() => {
    setViewBoxAction("maximize");
  }, []);

  const handleContainerDisplay = (action) => {
    setViewBoxAction(action);

    if (action === "maximize" && props.onMaximize) {
      props.onMaximize();
    }

    if (action === "semi-minimize" && props.onSemiMinimize) {
      props.onSemiMinimize();
    }

    if (action === "minimize" && props.onMinimize) {
      props.onMinimize();
    }

    if (action === null && props.onClose) {
      props.onClose();
    }
  };

  return (
    <div
      className="view-box-wrapper"
      style={{ display: viewBoxAction ? "initial" : "none" }}
    >
      <div className="view-box-header">
        <h5
          style={
            viewBoxAction !== "maximize"
              ? { fontSize: "calc(5px + 0.5vw)" }
              : {}
          }
        >
          {props.title}
        </h5>
        <div className="view-box-header-action-buttons-row">
          <FiMaximize
            className="view-box-header-action-button"
            onClick={() => handleContainerDisplay("maximize")}
          />
          <Gap value="calc(5px + 0.5vw)" />
          <FiMinimize
            className="view-box-header-action-button"
            onClick={() => handleContainerDisplay("semi-minimize")}
          />
          <Gap value="calc(5px + 0.5vw)" />
          <FiMinus
            className="view-box-header-action-button"
            onClick={() => handleContainerDisplay("minimize")}
          />
          <Gap value="calc(5px + 0.5vw)" />
          <FiX
            className="view-box-header-action-button"
            onClick={() => handleContainerDisplay(null)}
          />
        </div>
      </div>
      <div
        className="view-box-body"
        style={{
          display: viewBoxAction === "minimize" ? "none" : "flex",
          width:
            viewBoxAction === "maximize"
              ? props.viewBoxWidth
                ? props.viewBoxWidth
                : "600px"
              : "300px",
          height:
            viewBoxAction === "maximize"
              ? props.viewBoxHeight
                ? props.viewBoxHeight
                : "500px"
              : "250px",
        }}
      >
        {props.componentInView}
      </div>
    </div>
  );
};

export default ViewBox;
