import React, { useState, useEffect } from "react";
import data from "../../../Data";
import DropDownList from "../../Common/DropDownList";
import InputBox from "../../Common/InputBox";
import ChevronDownIcon from "../../../Icons/chevron_down.png";
import copyOfArrayOrObject from "../../../Functions/copyOfArrayOrObject";
import Button from "../../Common/Button";
import "./editmodal.css";
import { getActiveRoomItems } from "../../DesignPage/RoomItemsList/RoomItemsListFn";

const EditModal = (props) => {
  const [selectedCategory, setSelectedCategory] = useState("Select Category");
  const [quantity, setQuantity] = useState(null);
  const [updatedProduct, setUpdatedProduct] = useState(null);

  useEffect(() => {
    if (data.airtableCategory && props.editProduct.item.fulhausCategory) {
      let index = data.airtableCategory.findIndex((cat) => {
        return (
          cat.fields.Name === props.editProduct.item.fulhausCategory ||
          cat.fields.Name === props.editProduct.item.mappedFulhausCategory
        );
      });
      if (data.airtableCategory[index]) {
        setSelectedCategory(data.airtableCategory[index].fields.Name);
      }
    }
  }, [props]);

  const onCategoryChange = (e) => {
    if (e.target.name === "Select Category") {
      return;
    } else {
      setSelectedCategory(e.target.name);
      let newProduct = copyOfArrayOrObject(props.editProduct.item);
      newProduct.fulhausCategory = e.target.name;
      newProduct.mappedFulhausCategory = e.target.name;
      setUpdatedProduct(newProduct);
    }
  };

  const handleSave = () => {
    let tmpList = copyOfArrayOrObject(props.list);
    const index = tmpList[props.editProduct.roomType][
      props.tabIndex
    ].moodboard.findIndex(
      (i) => i.moodboardId === props.editProduct.item.moodboardId
    );
    tmpList[props.editProduct.roomType][props.tabIndex].moodboard[index] =
      updatedProduct;
    props.setStateAndStore(tmpList);
    props.setShowEditModal(false);
  };

  const handleInput = (e, type) => {
    if (
      type === "quantity" &&
      e.target.value !== props.editProduct.item.quantity
    ) {
      setQuantity(e.target.value);
      let newProduct = copyOfArrayOrObject(props.editProduct.item);
      newProduct.quantity = e.target.value;
      setUpdatedProduct(newProduct);
    }
  };

  return (
    <div className="editModal-container">
      <h3 className="editModal-header">Edit</h3>
      <div className="editModal-wrapper">
        <div className="editModal-fields">Category:</div>
        <div className="editModal-options">
          <DropDownList
            label={selectedCategory}
            labelFontSize="calc(8px + 0.5vw)"
            labelBorderRadius="5px"
            labelColor="#242627"
            labelBorder="1px solid #DCE1EB"
            labelAlign="space-between"
            labelHeight="calc(20px + 1vw)"
            labelPadding="0 calc(5px + 0.5vw)"
            listItems={[
              "Select Category",
              ...getActiveRoomItems(
                props.activeRoom,
                props.activeUnit,
                // data.project.currency
                 data.project?.CurrencyIsoCode
              ).map((item) => item.name),
              ...data.airtableCategory.map(
                (category) => category.fields && category.fields.Name
              ),
            ]}
            listPadding="calc(5px + 0.5vw)"
            listMargin="0 0 calc(5px + 0.5vw) 0"
            listAlign="left"
            icon={ChevronDownIcon}
            iconColorInvert={true}
            iconSize="10px"
            resetLabel={true}
            resetLabelOnChange={true}
            resetListItemsOnChange={true}
            onSelect={(e) => onCategoryChange(e)}
          />
        </div>

        <div className="editModal-fields">Quantity:</div>
        <div className="editModal-options">
          <InputBox
            name="SearchInput"
            activeBorder={true}
            background=" #FFFFFF"
            border="1px solid #DCE1EB"
            padding="0 calc(5px + 0.5vw)"
            borderRadius="5px"
            width="12%"
            height="15px"
            fontSize="calc(8px + 0.5vw)"
            margin="0"
            textAlign="left"
            placeholder="Quantity"
            value={quantity ? quantity : props.editProduct.item.quantity}
            onChange={(e) => handleInput(e, "quantity")}
          />
        </div>
      </div>
      <div className="editModal-buttons">
        <Button
          name="save"
          background="#FFFFFF"
          borderRadius="3px"
          border="1px solid #242627"
          enabled={updatedProduct}
          width="120px"
          height="65px"
          height="calc(10px + 1vw)"
          padding="0 calc(10px + 1vw)"
          margin=" 0 10px 0"
          loadingColor={"#242627"}
          onClick={handleSave}
          color="#242627"
          label="Save"
          fontSize="calc(8px + 0.5vw)"
        />
      </div>
    </div>
  );
};

export default EditModal;
