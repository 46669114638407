const getActiveRoomItems = (activeRoom, activeUnit, projectCurrency) => {
  let roomItems = [];

  activeUnit.quoteItems.forEach((quoteItem) => {
    const itemRoomName = quoteItem.room.description;
    quoteItem.items.forEach((item) => {
      if (activeRoom.description === itemRoomName) {
        roomItems.push({
          unitTitle: activeUnit.unit.fields["Item Name"],
          roomName: activeRoom.description,
          name: item.fields["Item Name"],
          qty: item.fields[activeUnit.unit.fields.SKU],
          budgetPrice: +item.fields[
            "Sale Price (" + projectCurrency + ")"
          ].toFixed(2),
        });
      }
    });
  });

  return roomItems;
};

export { getActiveRoomItems };
