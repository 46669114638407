import React from 'react';
import './RoomItemsList.css';
import data from '../../../Data';
import { connect } from 'react-redux';
import { getActiveRoomItems } from './RoomItemsListFn';
import { v4 as uuidv4 } from 'uuid';
import ItemCard from './ItemCard/ItemCard';

const UnconnectedRoomItemsList = (props) => {
  return (
    <div className='items-list-wrapper'>
      <div className='items-list-wrapper-fixed'>
        {getActiveRoomItems(
          props.activeRoom,
          props.activeUnit,
          // data.project.currency
          data.project?.CurrencyIsoCode
        ).map((item) => {
          return (
            <div
              key={uuidv4()}
              className='items-list-item-card-container'
              onClick={() => props.handleSelectedItem(item)}
            >
              <ItemCard item={{ name: item.name, qty: item.qty }} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

let mapStateToProps = (state) => {
  return {
    activeRoom: state.activeRoom,
    activeUnit: state.activeUnit,
  };
};
let RoomItemsList = connect(mapStateToProps)(UnconnectedRoomItemsList);
export default RoomItemsList;
