import React, { useState, useEffect } from "react";
import "./VendorFilter.css";
import DropDownList from "../../../../Common/DropDownList";
import ChevronDownIcon from "../../../../../Icons/chevron_down.png";

const VendorFilter = (props) => {
  const [selectedVendor, setSelectedVendor] = useState(
    "All vendors within radius"
  );

  useEffect(() => {
    if (props.selectedVendor) {
      setSelectedVendor(props.selectedVendor);
    } else {
      setSelectedVendor("All vendors within radius");
    }
  }, [props]);

  return (
    <div className="vendor-filter-wrapper">
      <h6 className="vendor-filter-label">Vendor</h6>
      <div className="vendor-filter-select-vendor">
        <DropDownList
          label={selectedVendor}
          labelFontSize="calc(8px + 0.5vw)"
          labelBorderRadius="5px"
          labelColor="#242627"
          labelBorder="1px solid #DCE1EB"
          labelAlign="space-between"
          labelHeight="calc(20px + 1vw)"
          labelPadding="0 calc(5px + 0.5vw)"
          listItems={[
            "All vendors within radius",
            ...props.vendors.map((vendor) => vendor && vendor.name),
          ]}
          listPadding="calc(5px + 0.5vw)"
          listMargin="0 0 calc(5px + 0.5vw) 0"
          listAlign="left"
          icon={ChevronDownIcon}
          iconColorInvert={true}
          iconSize="10px"
          resetLabel={true}
          resetLabelOnChange={true}
          resetListItemsOnChange={true}
          onSelect={(e) =>
            props.handleSelectVendorName(
              e.target.name === "All vendors within radius"
                ? null
                : e.target.name
            )
          }
        />
      </div>
    </div>
  );
};

export default VendorFilter;
