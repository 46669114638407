import React from "react";
import "./PageNotFound.css";
import Button from "../Common/Button";

const PageNotFound = (props) => {
  const goToHomePage = (event) => {
    props.history.push("/");
  };

  return (
    <div className="page-not-found-wrapper">
      <h1>404</h1>
      <h5>Oops... You tried to access a page that does not exist</h5>
      <small>
        You may have mistyped the address, or the page has been moved
      </small>
      <Button
        name="submit"
        background="#242627"
        borderRadius="5px"
        enabled={true}
        height="calc(20px + 2vw)"
        padding="0 calc(10px + 1vw)"
        onClick={(e) => goToHomePage(e)}
        color="#FFFFFF"
        label="Take me to the home page"
        fontSize="calc(8px + 0.5vw)"
      />
    </div>
  );
};

export default PageNotFound;
