import React, { useState, useEffect } from "react";
import "./ImageFilter.css";
import { CgSoftwareUpload } from "react-icons/cg";
import InputBox from "../../../../Common/InputBox";

const ImageFilter = (props) => {
  const [imageURLInput, setImageURLInput] = useState(null);

  useEffect(() => {
    setImageURLInput(props.similarImageURL);
  }, [props]);

  const handleImageURLInput = (event) => {
    setImageURLInput(event.target.value);
    props.handleSimilarImageURL(event.target.value);
  };

  return (
    <div className="image-filter-wrapper">
      <h6 className="image-filter-label">Similar Image</h6>
      <InputBox
        name="imageURL"
        type="text"
        background="#FFFFFF"
        border="1px solid #DCE1EB"
        activeBorder="1px solid #242627"
        borderRadius="5px"
        width="100%"
        height="calc(20px + 1vw)"
        padding="0 calc(5px + 0.5vw)"
        margin="0"
        fontSize="calc(8px + 0.5vw)"
        placeholder="Enter an image URL"
        value={imageURLInput}
        onChange={(e) => handleImageURLInput(e)}
      />
      <p>Or</p>
      <div
        className="image-filter-image-container"
        style={
          imageURLInput
            ? {
                backgroundImage: "url(" + imageURLInput + ")",
                cursor: "default",
              }
            : {}
        }
      >
        {!imageURLInput && (
          <React.Fragment>
            <CgSoftwareUpload className="image-filter-upload-icon" />
            <h6>Upload an image</h6>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default ImageFilter;
