import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import data from '../../../../Data';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import numberToCurrencyFormatter from '../../../../Functions/numberToCurrencyFormatter';
import './ProductModal.css';

const AirtalbeProductModal = (props) => {
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(null);
  useEffect(() => {
    if (data.project && props.item) {
      // let currency = data.project.Currency === 'CAD' ? 'CAD' : 'USD';
      let currency = data.project?.CurrencyIsoCode === 'CAD' ? 'CAD' : 'USD';
      setSelectedCurrency(currency);
      setSelectedPrice(
        currency === 'CAD' ? props.item.priceCAD : props.item.priceUSA
      );
    }
  }, []);
  return (
    <div className='productDetails-modal'>
      <div className='productDetails-modal-carousel-container'>
        <Carousel width={'450px'} showArrows={true} showThumbs={false}>
          {props.item.imageURLs.map((url) => {
            return (
              <img alt='product-img' style={{ width: '450px' }} src={url.url} />
            );
          })}
        </Carousel>
      </div>
      <div className='productDetails-modal-title-container'>
        <div className='productDetails-modal-title'>
          <h6 className='productDetails-modal-vendor'>
            BY {props.item.vendorName.toUpperCase().trim()}
          </h6>
          <h4 className='productDetails-modal-name'>{props.item.itemName}</h4>
          <h4 className='productDetails-modal-price'>
            {selectedCurrency &&
              selectedPrice &&
              numberToCurrencyFormatter(selectedPrice, selectedCurrency)}
          </h4>
        </div>
      </div>
      <div className='productDetails-modal-description-container'>
        <p>{props.item.vendorItemDescription}</p>
      </div>
      <div className='productDetails-modal-details'>
        <div className='productDetails-row'>
          <h5>DIMENSIONS</h5> <span>{props.item.dimension}</span>
        </div>
        <div className='productDetails-row'>
          <h5>METERIALS</h5> <span>{props.item.material}</span>
        </div>
        <div className='productDetails-row'>
          <h5>COLOR</h5> <span>{props.item.color}</span>
        </div>
        <div className='productDetails-row'>
          <h5>ClIPPED DATE</h5>{' '}
          <span>
            {props.item.createdTime && props.item.createdTime.split('T')[0]}
          </span>
        </div>
      </div>
    </div>
  );
};

export default AirtalbeProductModal;
