import React from "react";
import "./RoomsModal.css";
import RoomCard from "./RoomCard/RoomCard";
import { v4 as uuidv4 } from "uuid";

const RoomsModal = (props) =>
{
  return (
    <div className="rooms-modal-wrapper">
      <h4>Select a Room</h4>
      <div className="rooms-modal-rooms-container">
        {props.rooms.map((room) => {
          return (
            <div key={uuidv4()} className="rooms-modal-room-container">
              <RoomCard
                room={room}
                handleSelectedRoom={() => props.handleSelectedRoom(room)}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RoomsModal;
