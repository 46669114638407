import React, { useState, useEffect } from "react";
import "./DimensionsFilter.css";
import InputBox from "../../../../Common/InputBox";
import copyOfArrayOrObject from "../../../../../Functions/copyOfArrayOrObject";

const DimensionsFilter = (props) => {
  const [dimensionValues, setDimensionValues] = useState({});

  useEffect(() => {
    setDimensionValues(props.dimensions);
  }, [props]);

  const handleDimensions = (event) => {
    const newDimensionValues = copyOfArrayOrObject(dimensionValues);
    newDimensionValues[event.target.name] = event.target.value;
    setDimensionValues(newDimensionValues);
    props.handleDimensions(newDimensionValues);
  };

  return (
    <div className="dimensions-filter-wrapper">
      <h6 className="dimensions-filter-label">Dimensions</h6>
      <div className="dimensions-filter-inputs">
        <div style={{ width: "31%" }}>
          <InputBox
            name="width"
            type="text"
            background="#FFFFFF"
            border="1px solid #DCE1EB"
            activeBorder="1px solid #242627"
            borderRadius="5px"
            width="100%"
            height="calc(20px + 1vw)"
            padding="0 calc(5px + 0.5vw)"
            margin="0"
            fontSize="calc(8px + 0.5vw)"
            placeholder="W"
            suffixText={props.rangeValueLabel}
            value={dimensionValues.width}
            onChange={(e) => handleDimensions(e)}
          />
        </div>
        <div style={{ width: "31%" }}>
          <InputBox
            name="length"
            type="text"
            background="#FFFFFF"
            border="1px solid #DCE1EB"
            activeBorder="1px solid #242627"
            borderRadius="5px"
            width="100%"
            height="calc(20px + 1vw)"
            padding="0 calc(5px + 0.5vw)"
            margin="0"
            fontSize="calc(8px + 0.5vw)"
            placeholder="L"
            suffixText={props.rangeValueLabel}
            value={dimensionValues.length}
            onChange={(e) => handleDimensions(e)}
          />
        </div>
        <div style={{ width: "31%" }}>
          <InputBox
            name="height"
            type="text"
            background="#FFFFFF"
            border="1px solid #DCE1EB"
            activeBorder="1px solid #242627"
            borderRadius="5px"
            width="100%"
            height="calc(20px + 1vw)"
            padding="0 calc(5px + 0.5vw)"
            margin="0"
            fontSize="calc(8px + 0.5vw)"
            placeholder="H"
            suffixText={props.rangeValueLabel}
            value={dimensionValues.height}
            onChange={(e) => handleDimensions(e)}
          />
        </div>
      </div>
    </div>
  );
};

export default DimensionsFilter;
