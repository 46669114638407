import React from 'react';
import './DesignElementCard.css';
import Button from '../../../Common/Button';
import { FiTrash2 } from 'react-icons/fi';

const DesignElementCard = (props) => {
  return (
    <div className='design-element-card-wrapper'>
      <div className='design-element-card-wrapper-container'>
        <div
          className='design-element-card-design-element-image'
          style={{
            backgroundImage: 'url(' + props.element.bkgImageURL + ')',
          }}
        ></div>
        <div className='design-element-card-wrapper-container-overlay'>
          <div className='design-element-card-wrapper-container-overlay-delete'>
            <FiTrash2
              className='filters-close-icon'
              onClick={() => props.handleDelete(props.element._id)}
            />
          </div>
          <div className='design-element-card-wrapper-container-overlay-top'>
            <h5>{props.element.title.toUpperCase()}</h5>
            <h2>{props.element.description.toUpperCase()}</h2>
          </div>
          <div className='design-element-card-wrapper-container-overlay-bottom'>
            <Button
              name='select'
              background='#ff4e24'
              borderRadius='5px'
              width='100%'
              enabled={true}
              height='calc(20px + 1vw)'
              padding='0'
              onClick={() => props.handleSelectElement()}
              color='#ffffff'
              label='Select'
              fontSize='calc(8px + 0.5vw)'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesignElementCard;
