import React, { useState, useEffect } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

let DropDownComponent = (props) => {
  const [label, setLabel] = useState(props.label);
  const [component, setComponent] = useState(props.component);
  const [showComponent, setShowComponent] = useState(false);

  useEffect(() => {
    if (props.resetLabelOnChange) {
      setLabel(props.label);
    }

    if (props.resetComponentOnChange) {
      setComponent(props.component);
    }
  }, [props]);

  let style = {
    main: {
      position: 'relative',
      textAlign: 'right',
    },
    label: {
      background: props.labelBackground ? props.labelBackground : 'transparent',
      border: props.labelBorder ? props.labelBorder : 'none',
      color: props.labelColor ? props.labelColor : '#000000',
      boxShadow: props.labelBoxShadow,
      borderRadius: props.labelBorderRadius,
      width: props.labelWidth ? props.labelWidth : '100%',
      display: 'flex',
      justifyContent: props.labelAlign ? props.labelAlign : 'flex-end',
      alignItems: 'center',
      height: props.labelHeight,
      padding: props.labelPadding,
      fontSize: props.labelFontSize,
    },
    componentContainer: {
      position: 'absolute',
      top: '1',
      right: props.componentContainerFloat
        ? props.componentContainerFloat === 'left' && '0'
        : '0',
      left:
        props.componentContainerFloat &&
        props.componentContainerFloat === 'right' &&
        '0',
      display: 'flex',
      flexDirection: 'column',
      background: props.componentContainerBackground
        ? props.componentContainerBackground
        : '#ffffff',
      padding: props.searchable ? ' 0 calc(5px + 0.5vw)' : 'calc(5px + 0.5vw)',
      minWidth: '100%',
      borderRadius: '10px',
      boxShadow: '0px 0px 20px rgba(23, 27, 58, 0.5)',
      overflow: 'scroll',
      maxHeight: '50vh',
      zIndex: '2001',
    },
  };

  const handleClicked = (event) => {
    event.preventDefault();
    event.stopPropagation();
    props.handleLabelClick && props.handleLabelClick(event);
    let _showComponent = showComponent;
    if (event.target.name && event.target.name !== 'label') {
      props.onSelect && props.onSelect(event);
      props.resetLabel && setLabel(event.target.name);
    }
    setShowComponent(!_showComponent);
  };

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setShowComponent(false);
      }}
    >
      <div style={style.main}>
        <button
          style={style.label}
          name='label'
          onClick={(e) => handleClicked(e)}
        >
          <h5 style={{ margin: '0' }}>{label}</h5>
          {props.icon && (
            <img
              style={{
                marginLeft: '10px',
                filter: props.iconColorInvert ? 'invert(1)' : 'invert(0)',
              }}
              width={props.iconSize}
              src={props.icon}
              alt=''
            />
          )}
        </button>

        {showComponent && (
          <div style={style.componentContainer}>{component}</div>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default DropDownComponent;
