import React, { useState, useEffect } from "react";
import "./DesignPage.css";
import data from "../../Data";
import NavBar from "../Common/NavBar/NavBar";
import numberToCurrencyFormatter from "../../Functions/numberToCurrencyFormatter";
import Footer from "../Common/Footer/Footer";
import { FiHome } from "react-icons/fi";
import { BsDashCircleFill, BsPlusCircleFill } from "react-icons/bs";
import { connect } from "react-redux";
import { FiMenu } from "react-icons/fi";
import PageModal from "../Common/PageModal";
import RoomsModal from "../RoomsModal/RoomsModal";
import DesignMenus from "./DesignMenus/DesignMenus";
import RoomItemsList from "./RoomItemsList/RoomItemsList";
import { v4 as uuidv4 } from "uuid";
import Products from "./Products/Products";
import OutsideClickHandler from "react-outside-click-handler";
import MoodBoard from "../moodboard/MoodBoard";
import { getMeta, getImgArray } from "../../Functions/ImageFunctions";
import StylesModal from "../StylesModal/StylesModal";
import ViewBox from "../Common/ViewBox/ViewBox";
import StylesViewBox from "./StylesViewBox/StylesViewBox";
import DocumentsViewBox from "./DocumentsViewBox/DocumentsViewBox";
import Gap from "../Common/Gap";
import NotesViewBox from "./NotesViewBox/NotesViewBox";
import DesignElements from "./DesignElements/DesignElements";
import BlockLoading from "react-loadingg/lib/BlockLoading";
import ProductModal from "../moodboard/canvas/Modals/ProductModal";
import AirtableProductModal from "../moodboard/canvas/Modals/AirtableProductModal";
import useWindowDimensions from "../../Hooks/useWindowDimensions";
import { AiOutlinePlus } from "react-icons/ai";
import { MdZoomOutMap } from "react-icons/md";
import copyOfArrayOrObject from "../../Functions/copyOfArrayOrObject";
import { FiX } from "react-icons/fi";
import ResizePanel from "react-resize-panel";

const UnconnectedDesignPage = (props) => {
  const { height, width } = useWindowDimensions();
  const [isLoading, setIsLoading] = useState(true);
  const [showRooms, setShowRooms] = useState(false);
  const [showAllSelectors, setShowAllSelectors] = useState(true);
  const [allSelectorsButtonClicked, setAllSelectorsButtonClicked] =
    useState(false);
  const [showProducts, setShowProducts] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [designCanvasData, setDesignCanvasData] = useState({});
  const [designCanvasZoomValue, setDesignCanvasZoomValue] = useState(1);
  const [openStyleView, setOpenStyleView] = useState(false);
  const [styleViewMinimized, setStyleViewMinimized] = useState(false);
  const [showStylesModal, setShowStylesModal] = useState(false);
  const [openDocumentsView, setOpenDocumentsView] = useState(false);
  const [documentsViewMinimized, setDocumentsViewMinimized] = useState(false);
  const [openNotesView, setOpenNotesView] = useState(false);
  const [showProductDetail, setShowProductDetail] = useState(false);
  const [productDetail, setProductDetail] = useState(null);
  const [notesViewMinimized, setNotesViewMinimized] = useState(false);
  const [showDesignElements, setShowDesignElements] = useState(false);
  const [airtalbeProductDetail, setAirtableProductDetail] = useState(null);
  const [activeTabId, setActiveTabId] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    if (!data.project) {
      props.history.push("/projects");
      setIsLoading(false);
      return;
    }
    let roomList = {};
    let selectedUnit = "";
    if (props.activeUnit) {
      selectedUnit = props.activeUnit.unit.fields["Item Name"];
      props.activeUnit.roomList.forEach(
        (room) =>
          (roomList[room.description.trim()] = [
            {
              tabId: uuidv4(),
              moodboard: [],
              meta: {},
              text: {},
              activeTab: true,
            },
          ])
      );

      const copyOfMoodboardData = copyOfArrayOrObject(props.moodboardData);
      copyOfMoodboardData[selectedUnit] = {
        ...roomList,
        ...copyOfMoodboardData[selectedUnit],
      };
      if (props.moodboardData[selectedUnit]) {
        let tmp = Object.assign({}, copyOfMoodboardData[selectedUnit]);
        props.dispatch({
          type: "set-moodboardData1",
          value: tmp,
          unit: selectedUnit,
        });

        setDesignCanvasData(tmp);
        setIsLoading(false);
        return;
      }
      // props.dispatch({
      //   type: 'set-moodboardData',
      //   value: roomList,
      //   unit: selectedUnit,
      // });
      setDesignCanvasData(roomList);
      setIsLoading(false);
    }
  }, [props.activeUnit]);

  useEffect(() => {
    let selectedUnit =
      props.activeUnit && props.activeUnit.unit.fields["Item Name"];
    if (props.moodboardData[selectedUnit] && props.activeUnit) {
      setDesignCanvasData(props.moodboardData[selectedUnit]);
    }
  }, [props.moodboardData]);

  const goToUnitsPage = () => {
    props.history.push("/units");
  };

  const setStateAndStore = (currentState) => {
    setDesignCanvasData(currentState);
    props.dispatch({
      type: "set-moodboardData",
      value: currentState,
      unit: props.activeUnit.unit.fields["Item Name"],
    });
  };

  const handleSelectedRoom = (room) => {
    props.dispatch({
      type: "set-activeRoom",
      value: room,
    });
    setShowRooms(false);
    setShowAllSelectors(true);
  };

  const handleToggleShowAllSelectors = () => {
    setAllSelectorsButtonClicked(true);
    const currentShowAllSelectorsState = showAllSelectors;
    setShowAllSelectors(!currentShowAllSelectorsState);
    setAllSelectorsButtonClicked(false);
  };

  const handleSelectedItem = (item) => {
    setSelectedItem(item);
    setShowProducts(true);
    // setShowAllSelectors(false);
  };

  const handleSelectProduct = async (product, roomType) => {
    let index = designCanvasData[roomType].findIndex(
      (tab) => tab.activeTab === true
    );
    let modifiedProduct = {};
    let newArr = designCanvasData[roomType][index].moodboard;
    let allTabsData = designCanvasData[roomType];
    if ((product.MAP || product.bkgImageURL) && !product.airtableProduct) {
      let dims = await getMeta(product.bkgImageURL || product.imageURLs[0]);
      modifiedProduct = {
        ...product,
        MAP: props.exchangeRate
          ? product.MAP * props.exchangeRate
          : product.MAP,
        tradePrice: props.exchangeRate
          ? product.tradePrice * props.exchangeRate
          : product.tradePrice,
        MSRP: props.exchangeRate
          ? product.MSRP * props.exchangeRate
          : product.MSRP,
        displayedImage: product.imageURLs ? product.imageURLs[0] : null,
        quantity: 1,
        moodboardMeta: {
          position: {
            top: product.top || 50,
            left: product.left || 100,
            zIndex: product.zIndex || 0,
          },
          aspectRatio: true,
          dimensions: dims,
          rotation: 0,
          flipImg: false,
        },
        moodboardId: uuidv4(),
        isDragging: false,
        isResizing: false,
        isCropping: false,
        ref: null,
      };
    }
    if (product.airtableProduct && !product.euProduct) {
      let dims = await getMeta(product.imageURLs[0].url);
      let imgArray = await getImgArray(product.imageURLs);
      modifiedProduct = {
        ...product,
        // tradePrice: data.project.Currency.includes("CAD")
           tradePrice: data.project.CurrencyIsoCode.includes("CAD")
          ? product.priceCAD
          : product.priceUSA,
        imageURLs: imgArray,
        displayedImage: product.imageURLs ? product.imageURLs[0].url : null,
        quantity: 1,
        moodboardMeta: {
          position: {
            top: product.top || 50,
            left: product.left || 100,
            zIndex: product.zIndex || 0,
          },
          aspectRatio: true,
          dimensions: dims,
          rotation: 0,
          flipImg: false,
        },
        moodboardId: uuidv4(),
        isDragging: false,
        isResizing: false,
        isCropping: false,
        ref: null,
      };
    }
    if (product.airtableProduct && product.euProduct) {
      let dims = await getMeta(product.imageURLs[0].url);
      let imgArray = await getImgArray(product.imageURLs);
      modifiedProduct = {
        ...product,
        // tradePrice: data.project.Currency.includes("EUR")
           tradePrice: data.project.CurrencyIsoCode.includes("EUR")
          ? product.tradePriceEU
          : product.tradePriceGBP === "unavailable"
          ? product.tradePriceEU
          : product.tradePriceGBP,
        imageURLs: imgArray,
        quantity: 1,
        displayedImage: product.imageURLs ? product.imageURLs[0].url : null,
        moodboardMeta: {
          position: {
            top: product.top || 50,
            left: product.left || 100,
            zIndex: product.zIndex || 0,
          },
          aspectRatio: true,
          dimensions: dims,
          rotation: 0,
          flipImg: false,
        },
        moodboardId: uuidv4(),
        isDragging: false,
        isResizing: false,
        isCropping: false,
        ref: null,
      };
    }
    newArr.push(modifiedProduct);
    allTabsData[index] = {
      ...designCanvasData[roomType][index],
      moodboard: newArr,
    };
    setStateAndStore({ ...designCanvasData, [roomType]: allTabsData });
  };

  const handleSelectedStyle = (style) => {
    setShowStylesModal(false);
  };

  const handleSelectedDesignElement = (element) => {
    setShowDesignElements(false);
  };

  const handleShowDetails = (item, action) => {
    if (action === "set") {
      setShowProductDetail(true);
      setProductDetail(item);
    }
    if (action === "airtable-set") {
      setShowProductDetail(true);
      setAirtableProductDetail(item);
    }
    if (action === "close") {
      setProductDetail(null);
      setShowProductDetail(false);
      setAirtableProductDetail(null);
    }
  };

  const handleGoToQuotes = () => {
    // let url = `https://quotebuilder.fulhaus.com/start/${data.project.title}`;
    let url = `https://quotebuilder.fulhaus.com/start?${data.project.Opportunity_ID__c}`;
    var win = window.open(url, "_blank");
    win.focus();
  };

  const handleAddTab = () => {
    let selectedRoom = props.activeRoom.description;
    let newMoodboardData = copyOfArrayOrObject(props.moodboardData);
    let selectedUnit = props.activeUnit.unit.fields["Item Name"];

    let index = newMoodboardData[selectedUnit][selectedRoom].findIndex(
      (tab) => tab.activeTab === true
    );

    let roomData = newMoodboardData[selectedUnit][selectedRoom][index];

    newMoodboardData[selectedUnit][selectedRoom][index] = {
      ...roomData,
      activeTab: false,
    };
    let tabId = uuidv4();
    newMoodboardData[selectedUnit][selectedRoom].push({
      tabId: tabId,
      moodboard: [],
      meta: {},
      text: {},
      activeTab: true,
    });
    setActiveTabId(tabId);
    setStateAndStore(newMoodboardData[selectedUnit]);
  };

  const handleTabClick = (tab) => {
    setActiveTabId(tab.tabId);
    let selectedRoom = props.activeRoom.description;
    let newMoodboardData = copyOfArrayOrObject(props.moodboardData);
    let selectedUnit = props.activeUnit.unit.fields["Item Name"];
    //set activetab to false
    let index1 = newMoodboardData[selectedUnit][selectedRoom].findIndex(
      (tab) => tab.activeTab === true
    );
    let roomData1 = newMoodboardData[selectedUnit][selectedRoom][index1];
    newMoodboardData[selectedUnit][selectedRoom][index1] = {
      ...roomData1,
      activeTab: false,
    };
    // set onclicked tab to activeTab
    let index2 = newMoodboardData[selectedUnit][selectedRoom].findIndex(
      (t) => t.tabId === tab.tabId
    );
    let roomData2 = newMoodboardData[selectedUnit][selectedRoom][index2];
    newMoodboardData[selectedUnit][selectedRoom][index2] = {
      ...roomData2,
      activeTab: true,
    };
    setStateAndStore(newMoodboardData[selectedUnit]);
  };

  const handleRemoveDesignTab = () => {};

  return (
    <React.Fragment>
      <div className="design-page-wrapper">
        <NavBar
          // projectTitle={data.project && data.project.title}
          projectTitle={data.project?.Name}
          // projectAddress={data.project && data.project["project address"]}
           projectAddress={data.project && data.project?.address}
          // projectBudget={
          //   data.project &&
          //   numberToCurrencyFormatter(data.project.value, data.project.currency)
          // }
              projectBudget={
            data.project &&
            numberToCurrencyFormatter(data.project.Amount, data.project.CurrencyIsoCode)
          }
          actionButtons={true}
        />
        {isLoading ? (
          <div className="design-page-body">
            <BlockLoading color="#b8c5d3" />
          </div>
        ) : (
          <React.Fragment>
            <div className="design-page-body">
              <div className="design-page-header">
                <FiHome
                  className="design-page-header-icon"
                  onClick={() => goToUnitsPage()}
                />
                <h3>
                  {props.activeUnit.unit.fields["Item Name"] + " - "}
                  <span onClick={() => setShowRooms(true)}>
                    {props.activeRoom && props.activeRoom.description + "  >"}
                  </span>
                </h3>
              </div>
              <div className="design-page-tabs-container">
                {props.activeRoom &&
                  designCanvasData?.[props.activeRoom.description]?.map(
                    (tab, index) => {
                      return (
                        <div
                          key={index}
                          className="design-page-tab"
                          style={
                            tab.activeTab
                              ? {
                                  backgroundColor: "#ffffff",
                                  boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.01)",
                                  color: "#242627",
                                }
                              : {}
                          }
                          onClick={() => handleTabClick(tab)}
                        >
                          <h5>{index + 1}</h5>
                          {designCanvasData[props.activeRoom.description]
                            .length > 1 && (
                            <div>
                              <FiX
                                className="design-page-tab-close-icon"
                                onClick={() => handleRemoveDesignTab()}
                              />
                            </div>
                          )}
                        </div>
                      );
                    }
                  )}
                <div
                  className="design-page-tab-add-icon-wrapper"
                  onClick={() => handleAddTab()}
                >
                  <AiOutlinePlus className="design-page-tab-add-icon" />
                </div>
              </div>
              <div className="design-page-design-canvas">
                <MoodBoard
                  activeTabId={activeTabId}
                  roomList={designCanvasData}
                  zoomValue={designCanvasZoomValue}
                  handleShowDetails={(item, action) =>
                    handleShowDetails(item, action)
                  }
                />

                <div className="design-page-design-canvas-zoom-buttons">
                  <BsPlusCircleFill
                    className="design-page-design-canvas-zoom-button"
                    onClick={() =>
                      setDesignCanvasZoomValue(designCanvasZoomValue + 0.1)
                    }
                  />
                  <BsDashCircleFill
                    className="design-page-design-canvas-zoom-button"
                    onClick={() =>
                      setDesignCanvasZoomValue(designCanvasZoomValue - 0.1)
                    }
                  />
                  <MdZoomOutMap
                    className="design-page-design-canvas-zoom-button"
                    onClick={() => setDesignCanvasZoomValue(1)}
                  />
                </div>
                {!showProducts && (
                  <div
                    className="design-page-design-canvas-selectors-button-container"
                    onClick={() => handleToggleShowAllSelectors()}
                  >
                    <FiMenu className="design-page-design-canvas-selectors-button" />
                  </div>
                )}
              </div>
              {showAllSelectors && (
                <>
                  {!showProducts && (
                    <div className="design-page-design-canvas-design-menus-container">
                      <DesignMenus
                        handleShowStyle={() => setOpenStyleView(true)}
                        handleShowDocuments={() => setOpenDocumentsView(true)}
                        handleShowNotes={() => setOpenNotesView(true)}
                        handleShowDesignElements={() => {
                          setShowDesignElements(true);
                          setShowAllSelectors(false);
                        }}
                        handleGoToQuotes={handleGoToQuotes}
                      />
                    </div>
                  )}

                  <div className="design-page-design-canvas-design-items-container">
                    <RoomItemsList
                      handleSelectedItem={(i) => handleSelectedItem(i)}
                    />
                  </div>
                </>
              )}
              <div className="design-page-design-canvas-view-boxes">
                <Gap value="calc(5px + 0.5vw)" />
                {openNotesView && (
                  <ViewBox
                    title="Project Notes/Comments"
                    viewBoxWidth="400px"
                    viewBoxHeight="400px"
                    componentInView={
                      <NotesViewBox notesViewMinimized={notesViewMinimized} />
                    }
                    onSemiMinimize={() => setNotesViewMinimized(true)}
                    onMaximize={() => setNotesViewMinimized(false)}
                    onClose={() => setOpenNotesView(false)}
                  />
                )}
                <Gap value="calc(5px + 0.5vw)" />
                {openDocumentsView && (
                  <ViewBox
                    title="Project Documents"
                    viewBoxWidth="400px"
                    viewBoxHeight="400px"
                    componentInView={
                      <DocumentsViewBox
                        documentsViewMinimized={documentsViewMinimized}
                      />
                    }
                    onSemiMinimize={() => setDocumentsViewMinimized(true)}
                    onMaximize={() => setDocumentsViewMinimized(false)}
                    onClose={() => setOpenDocumentsView(false)}
                  />
                )}
                <Gap value="calc(5px + 0.5vw)" />
                {openStyleView && (
                  <ViewBox
                    title="Styles"
                    componentInView={
                      <StylesViewBox
                        designStyles={props.activeUnit.designStyles}
                        styleViewMinimized={styleViewMinimized}
                        showStyleModal={() => setShowStylesModal(true)}
                      />
                    }
                    onSemiMinimize={() => setStyleViewMinimized(true)}
                    onMaximize={() => setStyleViewMinimized(false)}
                    onClose={() => setOpenStyleView(false)}
                  />
                )}
                <Gap value="calc(5px + 0.5vw)" />
              </div>
            </div>
            {showProducts && (
              <OutsideClickHandler
                onOutsideClick={(e) => {
                  setTimeout(function () {}, 10);
                }}
              >
                <ResizePanel
                  direction="n"
                  handleClass={{ display: "none" }}
                  style={{ minHeight: "80%", height: "400px" }}
                >
                  <div className="design-page-products">
                    <div className="design-page-products-close-container">
                      <FiX
                        className="filters-close-icon"
                        onClick={() => setShowProducts(false)}
                      />
                    </div>
                    <Products
                      selectedItem={selectedItem}
                      handleSelectProduct={(p, roomType) =>
                        handleSelectProduct(p, roomType)
                      }
                      detailsModal={(item, type) =>
                        type === "airtableProduct"
                          ? handleShowDetails(item, "airtable-set")
                          : handleShowDetails(item, "set")
                      }
                    />
                  </div>
                </ResizePanel>
              </OutsideClickHandler>
            )}
            {showDesignElements && (
              <OutsideClickHandler
                onOutsideClick={(e) => {
                  setTimeout(function () {
                    setShowDesignElements(false);
                  }, 10);
                }}
              >
                <div className="design-page-products">
                  <DesignElements
                    selectedItem={selectedItem}
                    handleSelectedDesignElement={(e, roomType) =>
                      handleSelectProduct(e, roomType)
                    }
                  />
                </div>
              </OutsideClickHandler>
            )}
          </React.Fragment>
        )}
        <Footer />
      </div>
      {props.activeUnit && (
        <PageModal
          visible={showRooms}
          component={
            <RoomsModal
              rooms={props.activeUnit.roomList}
              handleSelectedRoom={(r) => handleSelectedRoom(r)}
            />
          }
          borderRadius="5px"
          padding="calc(20px + 2vw)"
          onClose={() => setShowRooms(false)}
        />
      )}
      {showProductDetail && productDetail && (
        <PageModal
          visible={showProductDetail}
          component={<ProductModal item={productDetail} />}
          borderRadius="5px"
          padding="calc(20px + 2vw)"
          onClose={() => handleShowDetails(null, "close")}
        />
      )}
      {showProductDetail && airtalbeProductDetail && (
        <PageModal
          visible={showProductDetail}
          component={<AirtableProductModal item={airtalbeProductDetail} />}
          borderRadius="5px"
          padding="calc(20px + 2vw)"
          onClose={() => handleShowDetails(null, "close")}
        />
      )}
      <PageModal
        visible={showStylesModal}
        component={
          <StylesModal handleSelectedStyle={(s) => handleSelectedStyle(s)} />
        }
        borderRadius="5px"
        padding="calc(20px + 2vw)"
        onClose={() => setShowStylesModal(false)}
      />
    </React.Fragment>
  );
};

let mapStateToProps = (state) => {
  return {
    moodboardData: state.moodboardData,
    activeRoom: state.activeRoom,
    activeUnit: state.activeUnit,
  };
};
let DesignPage = connect(mapStateToProps)(UnconnectedDesignPage);
export default DesignPage;
