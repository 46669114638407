import React from "react";
import "./PriceFilter.css";
import RangeSelector from "../../../../Common/RangeSelector";

const PriceFilter = (props) => {
  const handlePriceRange = (priceRange) => {
    props.handlePriceRange({
      priceMin: priceRange.min,
      priceMax: priceRange.max,
    });
  };

  return (
    <div className="price-filter-wrapper">
      <RangeSelector
        label="price"
        labelColor="#4d4d4d"
        labelMargin="0 0 0 10px"
        rangeSize={props.rangeSize}
        rangeValueLabel={props.rangeValueLabel}
        minRange={props.priceRange.min}
        maxRange={props.priceRange.max}
        onChange={(v) => handlePriceRange(v)}
      />
    </div>
  );
};

export default PriceFilter;
