import { createStore } from "redux";
import ReduxReducer from "./Reducer";

const devTools = { tools: undefined };
if (process.env.REACT_APP_ENV === "development") {
  devTools.tools =
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.__REDUX_DEVTOOLS_EXTENSION__();
}

const ReduxStore = createStore(
  ReduxReducer,
  {
    moodboardData: {},
    activeRoom: null,
    activeUnit: null,
    revisionNum: null,
  },
  devTools.tools
);

export default ReduxStore;
