import React, { useState, useEffect } from 'react';
import produce from 'immer';
import InputBox from '../../../Common/InputBox';
import Button from '../../../Common/Button';
import { CgSoftwareUpload } from 'react-icons/cg';
import data from '../../../../Data';
import './DesignElementModal.css';

const DesignElementModal = (props) => {
  const [state, setState] = useState({
    description: null,
    title: null,
    file: null,
  });

  const [title, setTitle] = useState('');
  const [file, setFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);

  const [description, setDescription] = useState('');
  const [error, setError] = useState(null);
  const [designElementCategories, setDesignElementCategories] = useState([]);

  useEffect(() => {
    setDesignElementCategories(
      data.designElementCategories.map((element) => element.title)
    );
  }, []);

  const handleFile = (event) => {
    event.preventDefault();
    setFile(event.target.files[0]);
    setFilePreview(URL.createObjectURL(event.target.files[0]));
  };

  const handleTitleInput = (evt) => {
    setTitle(evt.target.value);
  };
  const handleDescriptionInput = (evt) => {
    setDescription(evt.target.value);
  };

  const handleSubmit = () => {
    if (file && title && description) {
      props.addDesignElement({
        file: file,
        title: title,
        description: description,
      });
    } else {
      setError('Empty fields');
    }
  };

  return (
    <div className='designElement-modal-wrapper'>
      <h6 className='designElement-modal-label'>Upload</h6>
      {error && <h6 className='designElement-modal-label-error'>{error}</h6>}
      <InputBox
        name='title'
        type='text'
        background='#FFFFFF'
        border='1px solid #DCE1EB'
        activeBorder='1px solid #242627'
        borderRadius='5px'
        width='100%'
        height='calc(20px + 1vw)'
        padding='0 calc(5px + 0.5vw)'
        margin='0'
        fontSize='calc(8px + 0.5vw)'
        placeholder='Enter title'
        value={title}
        onChange={(e) => handleTitleInput(e)}
      />
      <InputBox
        name='description'
        type='text'
        background='#FFFFFF'
        border='1px solid #DCE1EB'
        activeBorder='1px solid #242627'
        borderRadius='5px'
        width='100%'
        height='calc(20px + 1vw)'
        padding='0 calc(5px + 0.5vw)'
        margin='0'
        fontSize='calc(8px + 0.5vw)'
        placeholder='Enter description'
        value={description}
        onChange={(e) => handleDescriptionInput(e)}
      />
      <div
        className='image-filter-image-container'
        style={
          filePreview
            ? {
                backgroundImage: 'url(' + filePreview + ')',
                cursor: 'default',
              }
            : {}
        }
      >
        {!state.file && (
          <React.Fragment>
            {!filePreview && (
              <>
                <CgSoftwareUpload className='image-filter-upload-icon' />
                <h6>Upload an image</h6>
              </>
            )}
            <input type='file' onChange={(evt) => handleFile(evt)} />
          </React.Fragment>
        )}
      </div>
      <Button
        name='Submit'
        background='#242627'
        borderRadius='5px'
        width='65%'
        enabled={true}
        height='calc(20px + 1vw)'
        padding='0 calc(10px + 1vw)'
        onClick={() => handleSubmit()}
        color='#FFFFFF'
        label='Submit'
        fontSize='calc(8px + 0.5vw)'
      />
    </div>
  );
};

export default DesignElementModal;
