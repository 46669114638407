import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import Canvas from "./canvas/Canvas";
import { withRouter } from "react-router-dom";
import data from "../../Data";
import loadingImg from "./images/loading_removebg.gif";
import copyOfArrayOrObject from "../../Functions/copyOfArrayOrObject";
import { useAlert } from "react-alert";
import "../DesignPage/DesignCanvas/DesignCanvas.css";
import fetchUtils from "../../Functions/fetchUtils";
import { PDFExport } from "@progress/kendo-react-pdf";
import EditModal from "./EditModal/EditModal";
import PageModal from "../Common/PageModal";

const pdfRef = React.createRef();

const UnconnectedMoodBoard = (props) => {
  const [list, setList] = useState(props.roomList);
  const [tabIndex, setTabIndex] = useState(null);
  const [editProduct, setEditProduct] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const alert = useAlert();

  useEffect(() => {
    setList(props.roomList);
    let index = props.roomList[props.activeRoom.description].findIndex(
      (tab) => tab.activeTab === true
    );
    setTabIndex(index);
  }, [props.roomList]);

  useEffect(() => {
    let index = props.roomList[props.activeRoom.description].findIndex(
      (tab) => tab.activeTab === true
    );
    setTabIndex(index);
  }, []);

  const setStateAndStore = (currentState) => {
    setList(currentState);
    props.dispatch({
      type: "set-moodboardData",
      value: currentState,
      unit: props.activeUnit.unit.fields["Item Name"],
    });
  };

  const onChange = (newList, room) => {
    let tmpList = copyOfArrayOrObject(list);
    tmpList[room][tabIndex] = newList;
    setStateAndStore(tmpList);
  };

  const removeBG = async (item, room) => {
    let tmpList = Object.assign({}, list);
    const index = tmpList[room][tabIndex].moodboard.findIndex(
      (i) => i.moodboardId === item.moodboardId
    );
    let tmpImg = tmpList[room][tabIndex].moodboard[index].displayedImage;
    tmpList[room][tabIndex].moodboard[index].displayedImage = loadingImg;
    const imgIndex = tmpList[room][tabIndex].moodboard[
      index
    ].imageURLs.findIndex((url) => url === tmpImg);
    setStateAndStore({ ...tmpList });
    let data = {
      imageURL: tmpImg,
      imgIndex: imgIndex,
      imgArray: tmpList[room][tabIndex].moodboard[index].imageURLs,
      vendorName: item.vendorName,
      SKU: item.SKU,
    };

    let payload = await fetchUtils(`/removebg`, "POST", data);

    if (payload.success) {
      tmpList[room][tabIndex].moodboard[index].displayedImage = payload.newURL;

      alert.show(<h3 style={{ color: "#242627" }}>{payload.message}</h3>);
      setStateAndStore({ ...tmpList });
    } else {
      tmpList[room][tabIndex].moodboard[index].displayedImage = tmpImg;
      alert.show(<h3 style={{ color: "#242627" }}>{payload.message}</h3>);
      setStateAndStore({ ...tmpList });
    }
  };

  const setBackground = (hex, roomType) => {
    let tmp = Object.assign({}, list);
    tmp[roomType][tabIndex].meta = { background: hex };
    setStateAndStore(tmp);
  };

  const clearCanvas = (roomType) => {
    let newList = Object.assign({}, list);
    if (newList[roomType][tabIndex]) {
      newList[roomType][tabIndex] = {
        moodboard: [],
        meta: {},
        text: {},
        activeTab: true,
      };
      setStateAndStore(newList);
    }
  };

  const generatePdf = () => {
    pdfRef.current.save();
  };

  return (
    <>
      <PDFExport
        ref={pdfRef}
        paperSize="auto"
        // fileName={`Moodboard_-_${data.project && data.project.title}-${
         fileName={`Moodboard_-_${data.project?.Name}-${
          props.activeUnit && props.activeUnit.unit.fields["Item Name"]
        }_-_${props.activeRoom && props.activeRoom.description}`}
        author="Fulhaus"
      >
        <div
          className="design-canvas-wrapper"
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            transform: `scale(${props.zoomValue})`,
          }}
        >
          {
            <Canvas
              tabIndex={tabIndex}
              project={data.project}
              onChange={onChange}
              list={list}
              removeBG={removeBG}
              setBackground={setBackground}
              clearCanvas={(roomType) => clearCanvas(roomType)}
              setProductDetails={(productDetails) => {
                props.handleShowDetails(productDetails, "set");
              }}
              scale={props.zoomValue}
              generatePdf={generatePdf}
              editProduct={(item, roomType) => {
                setShowEditModal(true);
                setEditProduct({ item, roomType });
              }}
            />
          }
        </div>
      </PDFExport>
      {showEditModal && editProduct && (
        <PageModal
          visible={showEditModal}
          component={
            <EditModal
              tabIndex={tabIndex}
              editProduct={editProduct}
              list={list}
              activeRoom={props.activeRoom}
              activeUnit={props.activeUnit}
              setStateAndStore={setStateAndStore}
              setShowEditModal={setShowEditModal}
            />
          }
          borderRadius="5px"
          padding="calc(20px + 2vw)"
          onClose={() => setShowEditModal(false)}
          overLay="transparent"
        />
      )}
    </>
  );
};
let mapStateToProps = (state) => {
  return {
    activeUnit: state.activeUnit,
    activeRoom: state.activeRoom,
    moodboardData: state.moodboardData,
  };
};
let MoodBoard = connect(mapStateToProps)(UnconnectedMoodBoard);
export default withRouter(MoodBoard);
