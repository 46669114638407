import React, { useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import './miniCanvas.css';
import Text from './Text';
import ImageToBase64 from './ImageToBase64';

const MiniCanvas = (props) => {
  const [list, setList] = useState(null);
  const [roomType, setRoomType] = useState(null);
  const [backgroundColor, setBackgroundColor] = useState(null);
  const [textList, setTextList] = useState(null);
  const [zoomValue, setZoomValue] = useState(0.16);

  useEffect(() => {
    setList(props.list.moodboard);
    setRoomType(props.roomType);
    setBackgroundColor(props.list.meta.background);
    setTextList(props.list.text);
  }, []);

  return (
    <>
      {list && roomType && (
        <div
          style={{
            boxShadow: 'none',
            outline: 0,
            transform: `scale(${zoomValue})`,
            backgroundColor: backgroundColor || '#fff',
          }}
          className='miniCanvas-canvas'
        >
          {list.map((item, index) => {
            const dims = {
              width: item.moodboardMeta.dimensions.width,
              height: item.moodboardMeta.dimensions.height,
            };
            return (
              <>
                <Draggable
                  key={index + item._id}
                  defaultPosition={{
                    x: item.moodboardMeta.position.left,
                    y: item.moodboardMeta.position.top,
                  }}
                  disabled={true}
                >
                  <div
                    style={{
                      ...dims,
                      zIndex: item.moodboardMeta.position.zIndex,
                    }}
                    className='miniCanvas-item'
                  >
                    <div>
                      <ImageToBase64
                        miniCanvas={true}
                        style={dims}
                        url={item.bkgImageURL || item.displayedImage}
                        alt=''
                        imgArray={item.imageURLs ? item.imageURLs : null}
                        item={item}
                      />
                    </div>
                  </div>
                </Draggable>
              </>
            );
          })}
          {textList &&
            Object.keys(textList).map((textID, index) => (
              <>
                <Text
                  textID={textID}
                  textData={textList[textID]}
                  disabled={true}
                />
              </>
            ))}
        </div>
      )}
    </>
  );
};

export default MiniCanvas;
