import React, { useEffect, useState } from 'react';
import '../ProductCard/ProductCard.css';
import Button from '../../../Common/Button';
import { connect } from 'react-redux';
import Gap from '../../../Common/Gap';
import data from '../../../../Data';
import numberToCurrencyFormatter from '../../../../Functions/numberToCurrencyFormatter';

const UnconnectedAirtableProductCard = (props) => {
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [updatedProduct, setUpdatedProduct] = useState(null);
  useEffect(() => {
    if (data.project) {
      // let currency = data.project.Currency === 'CAD' ? 'CAD' : 'USD';
      let currency = data.project?.CurrencyIsoCode === 'CAD' ? 'CAD' : 'USD';
      setSelectedCurrency(currency);
      setSelectedPrice(
        currency === 'CAD' ? props.product.priceCAD : props.product.priceUSA
      );
    }
  }, []);

  useEffect(() => {
    if (data.airtableCategory && props.product.fulhausCategory) {
      let index = data.airtableCategory.findIndex((cat) => {
        return cat.id === props.product.fulhausCategory[0];
      });
      if (data.airtableCategory[index]) {
        let newProduct = props.product;
        newProduct.fulhausCategory = data.airtableCategory[index].fields.Name;
        newProduct.mappedFulhausCategory =
          data.airtableCategory[index].fields.Name;
        setUpdatedProduct(newProduct);
      }
    }
    if (typeof props.product.fulhausCategory === 'string') {
      setUpdatedProduct(props.product);
    }
  }, []);

  return (
    <>
      {updatedProduct && (
        <div className='product-card-wrapper'>
          <div className='product-card-wrapper-container'>
            <div
              className='product-card-product-image'
              style={{
                backgroundImage: 'url(' + props.product.imageURLs[0].url + ')',
              }}
            ></div>
            <div className='product-card-wrapper-container-overlay'>
              <div className='product-card-wrapper-container-overlay-top'>
                <h5>
                  {props.product.itemName.length > 25
                    ? props.product.itemName.substring(0, 25) + '...'
                    : props.product.itemName}
                </h5>
                <h2>
                  {selectedCurrency &&
                    selectedPrice &&
                    numberToCurrencyFormatter(selectedPrice, selectedCurrency)}
                </h2>
              </div>
              <div className='product-card-wrapper-container-overlay-bottom'>
                <Button
                  name='select'
                  background='#ff4e24'
                  borderRadius='5px'
                  width='100%'
                  enabled={true}
                  height='calc(20px + 1vw)'
                  padding='0'
                  onClick={() =>
                    props.handleSelectProduct(
                      updatedProduct,
                      props.activeRoom.description
                    )
                  }
                  color='#ffffff'
                  label='Select'
                  fontSize='calc(8px + 0.5vw)'
                />
                <Gap value='calc(2px + 0.2vw)' />
                <Button
                  name='details'
                  background='#242627'
                  borderRadius='5px'
                  width='100%'
                  enabled={true}
                  height='calc(20px + 1vw)'
                  padding='0'
                  onClick={() => props.detailsModal(props.product)}
                  color='#ffffff'
                  label='Details'
                  fontSize='calc(8px + 0.5vw)'
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

let mapStateToProps = (state) => {
  return {
    activeRoom: state.activeRoom,
  };
};
let AirtableProductCard = connect(mapStateToProps)(
  UnconnectedAirtableProductCard
);
export default AirtableProductCard;
