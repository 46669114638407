import React from 'react';
import './StylesModal.css';
import StylesCard from './StylesCard/StylesCard';
import { v4 as uuidv4 } from 'uuid';
import data from '../../Data';

const StylesModal = (props) => {
  return (
    <div className='styles-modal-wrapper'>
      <h4>Select a Style</h4>
      <div className='styles-modal-styles-container'>
        {data.designStyles &&
          data.designStyles.map((style) => {
            return (
              style.fields.Name && (
                <div key={uuidv4()} className='styles-modal-style-container'>
                  <StylesCard
                    style={style}
                    handleSelectedStyle={() => props.handleSelectedStyle(style)}
                  />
                </div>
              )
            );
          })}
      </div>
    </div>
  );
};

export default StylesModal;
