import React from "react";
import "./StylesCard.css";

const StylesCard = (props) => {
  const getStyleImage = (style) =>
  {

    let styleURL = "";

    if (style)
    {
          if (!style.fields["Unnamed Moodboard"]) {
            style.fields["Unnamed Moodboard"] = style.fields.Attachments ? style.fields.Attachments : [];
            
          }
      styleURL = style.fields["Unnamed Moodboard"][0] ? style.fields["Unnamed Moodboard"][0].url : "";
    }

    return styleURL;
  };
  return (
    <div
      className="style-card-wrapper"
      onClick={() => props.handleSelectedStyle()}
    >
      { props.style && <div
        className="style-card-style-image"
        style={{ backgroundImage: "url(" + getStyleImage(props.style) + ")" }}
      >
        <div className="style-card-style-name">
          <h5>{props.style.fields.Name}</h5>
        </div>
      </div>}
    </div>
  );
};

export default StylesCard;
