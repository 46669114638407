import React, { useEffect, useState } from 'react';
import './UnitCard.css';
import { AiTwotoneHome } from 'react-icons/ai';
import { connect } from 'react-redux';
import {
  getUnitItemCount,
  getTotalQtyPerUnit,
} from '../../../Functions/counterFn';
import data from '../../../Data';
import ProgressBar from '@ramonak/react-progress-bar';

const UnconnectedUnitCard = (props) => {
  const [count, setCount] = useState(0);
  const [qty, setQty] = useState(0);

  useEffect(() => {
    if (props.moodboardData && data.quote && props.unitName) {
      setCount(
        getUnitItemCount(
          data.quote.quoteData,
          props.moodboardData,
          props.unitName
        ) || 0
      );
      setQty(getTotalQtyPerUnit(data.quote.quoteData, props.unitName) || 0);
    }
  }, []);

  const unitStatus = [
    {
      status: 'No design yet',
      color: '#F63F41',
    },
  ];

  return (
    <div
      className='unit-card-wrapper'
      onClick={() => props.handleSelectedUnit()}
    >
      <div className='unit-card-description'>
        <h2>{props.unitName}</h2>
        <p>{props.unitDescription}</p>
      </div>
      <div className='items-list-item-counter'>
        <ProgressBar
          completed={Math.round((count / qty) * 100)}
          bgcolor='#1b9a51'
        />
      </div>
      <div className='unit-card-status'>
        <p style={{ color: unitStatus[0].color }}>{unitStatus[0].status}</p>
        <AiTwotoneHome
          className='unit-card-status-icon'
          style={{ color: unitStatus[0].color }}
        />
      </div>
    </div>
  );
};

let mapStateToProps = (state) => {
  return {
    activeRoom: state.activeRoom,
    activeUnit: state.activeUnit,
    moodboardData: state.moodboardData,
  };
};
let UnitCard = connect(mapStateToProps)(UnconnectedUnitCard);
export default UnitCard;
