import React, { useState } from 'react';
import './RoomCard.css';
import { AiFillContainer } from 'react-icons/ai';
import { connect } from 'react-redux';
import { BsFillCircleFill } from 'react-icons/bs';
import { useEffect } from 'react';
import {
  getRoomItemCount,
  getTotalQtyPerRoom,
} from '../../../Functions/counterFn';
import ProgressBar from '@ramonak/react-progress-bar';
import MiniCanvas from '../miniCanvas/MiniCanvas';

const UnconnectedRoomCard = (props) => {
  const [count, setCount] = useState(0);
  const [qty, setQty] = useState(0);
  const [roomList, setRoomList] = useState(null);
  useEffect(() => {
    if (props.moodboardData && props.activeUnit && props.room) {
      setCount(
        getRoomItemCount(
          props.moodboardData,
          props.activeUnit,
          props.room.description
        ) || 0
      );
      setQty(getTotalQtyPerRoom(props.activeUnit, props.room.description) || 0);
    }
  }, []);

  useEffect(() => {
    if (props.moodboardData && props.activeUnit) {
      // let unitList =
      //   props.moodboardData[props.activeUnit.unit.fields['Item Name']];
      // console.log("unitList", unitList, props.room.description)
      // if (unitList)
      // {
      //   console.log("unitList[props.room.description]['activeTab'] || null", unitList[props.room.description]['activeTab'] || null)
      //   setRoomList(unitList[props.room.description]['activeTab'] || null);
      // }
    }
  }, []);

  const roomStatusColors = {
    inComplete: '#F63F41',
    complete: '#08C157',
  };

  return (
    <div
      className='room-card-wrapper'
      onClick={() => props.handleSelectedRoom()}
    >
      <div className='room-card-icon-and-title-container'>
        {!roomList && (
          <AiFillContainer style={{ width: '50px', height: '50px' }} />
        )}
        <h5>{props.room.description}</h5>
      </div>
      <div className='room-card-miniCanvas-container'>
        {roomList && (
          <MiniCanvas list={roomList} roomType={props.room.description} />
        )}
      </div>
      <div className='room-card-progressBar-container'>
        <ProgressBar
          completed={Math.round((count / qty) * 100)}
          bgcolor='#1b9a51'
        />
      </div>
      <div className='room-card-status'>
        <BsFillCircleFill
          className='room-card-status-icon'
          style={{ color: roomStatusColors.inComplete }}
        />
      </div>
    </div>
  );
};

let mapStateToProps = (state) => {
  return {
    activeRoom: state.activeRoom,
    activeUnit: state.activeUnit,
    moodboardData: state.moodboardData,
  };
};
let RoomCard = connect(mapStateToProps)(UnconnectedRoomCard);
export default RoomCard;
