import React, { useEffect } from 'react';

import useContextMenu from '../../../../Hooks/UseContextMenu';

const CanvasMenu = (props) => {
  const { xPos, yPos, menu } = useContextMenu(props.outerRef);

  if (menu) {
    return (
      <ul
        style={{ top: yPos, left: xPos, zIndex: '10000' }}
        class='menu menu-show'
      >
        <li class='menu-item'>
          <button onClick={props.handleAddText} class='menu-btn'>
            <span class='menu-text'> Add text</span>
          </button>
        </li>

        <li class='menu-item'>
          <button onClick={props.handleBackgroundChange} class='menu-btn'>
            <span class='menu-text'>Background color</span>
          </button>
        </li>
        <li class='menu-item'>
          <button class='menu-btn' onClick={props.generatePdf}>
            <span class='menu-text'>Download canvas</span>
          </button>
        </li>
        <li class='menu-item'>
        <button class='menu-btn'>
          <span class='menu-text'>Undo</span>
        </button>
      </li>
        <div className='menu--separator' />
        <li class='menu-item'>
          <button onClick={props.handleClearCanvas} class='menu-btn'>
            <span class='menu-text'>Clear canvas</span>
          </button>
        </li>
      </ul>
    );
  }
  return <></>;
};

export default CanvasMenu;
