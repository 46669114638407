import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import { Provider as ReduxProvider } from 'react-redux';
import ReduxStore from './Redux/Store';
import { AiOutlineCloseCircle } from 'react-icons/ai';

const options = {
  position: positions.TOP_RIGHT,
  timeout: 5000,
  offset: '30px',
  transition: transitions.SCALE,
};

const alertStyle = {
  main: {
    backgroundColor: 'white',
    color: 'black !important',
    display: 'flex',
    marginTop: '50px',
    border: '1px solid #242627',
    borderRadius: '5px',
    padding: '5px',
  },
  btn: {
    backgroundColor: 'transparent',
    border: 'none',
    padding: '5px',
    color: '#242627',
  },
  msg: {
    color: 'black',
    margin: '7px',
  },
};

const AlertTemplate = ({ style, options, message, close }) => (
  <div style={alertStyle.main}>{message}</div>
);

ReactDOM.render(
  <React.StrictMode>
    <ReduxProvider store={ReduxStore}>
      <AlertProvider template={AlertTemplate} {...options}>
        <App />
      </AlertProvider>
    </ReduxProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
