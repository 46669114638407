import React, { useEffect, useState } from 'react';
import './Products.css';
import Filters from './Filters/Filters';
import data from '../../../Data';
import { SiAirtable, SiMongodb } from 'react-icons/si';
import { DisappearedLoading } from 'react-loadingg';
import { FiXCircle } from 'react-icons/fi';
import { FiFilter } from 'react-icons/fi';
import fetchUtils from '../../../Functions/fetchUtils';
import copyOfArrayOrObject from '../../../Functions/copyOfArrayOrObject';
import ProductCard from './ProductCard/ProductCard';
import NewProductCard from './NewProductCard/NewProductCard';
import { v4 as uuidv4 } from 'uuid';
import MoreProductsCard from './MoreProductsCard/MoreProductsCard';
import numberToCurrencyFormatter from '../../../Functions/numberToCurrencyFormatter';
import { BiSort } from 'react-icons/bi';
import { FiX } from 'react-icons/fi';
import AirtableProducts from './AirtableProducts/AirtableProducts';
import ProductModal from '../../moodboard/canvas/Modals/ProductModal';
import PageModal from '../../Common/PageModal';
import AirtableProductModal from '../../moodboard/canvas/Modals/AirtableProductModal';
import ResizePanel from 'react-resize-panel';

const Products = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [priceRange, setPriceRange] = useState({
    min: 0,
    max: props.selectedItem.budgetPrice,
  });
  const [rangeSize, setRangeSize] = useState({
    min: 0,
    max: props.selectedItem.budgetPrice + 1000,
  });
  const [vendors, setVendors] = useState([]);
  const [page, setPage] = useState(1);
  const [itemsLimit, setItemsLimit] = useState(20);
  const [totalProductCount, setTotalProductCount] = useState(null);
  const [filterParams, setFilterParams] = useState({});
  const [showFilters, setShowFilters] = useState(false);
  const [showSortOptions, setShowSortOptions] = useState(false);
  const [sortOption, setSortOption] = useState(null);
  const [sortOptionDetailsOption, setSortOptionDetailsOption] = useState(null);
  const [showAirtableProducts, setShowAirtableProducts] = useState(false);
  const [showProductDetail, setShowProductDetail] = useState(false);
  const [showAirtableProductDetail, setAirtableShowProductDetail] = useState(
    false
  );
  const [productDetail, setproductDetail] = useState(null);
  const [airtableProductDetail, setairtableProductDetail] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    getProducts();
    setShowAirtableProducts(false);
  }, [props.selectedItem.name]);

  useEffect(() => {
    if (showAirtableProducts) {
      getAirtableProducts();
    }
  }, [showAirtableProducts]);

  const getActiveFilters = (filterData) => {
    const filters = [];
    if (filterData.priceMin || filterData.priceMax) {
      filters.push({
        title: 'Price',
        text:
          numberToCurrencyFormatter(filterData.priceMin) +
          ' to ' +
          numberToCurrencyFormatter(filterData.priceMax),
        filterID: ['priceMin', 'priceMax'],
      });
    }

    if (filterData.radius) {
      filters.push({
        title: 'Radius',
        text: filterData.radius + ' mile radius',
        filterID: ['radius'],
      });
    }

    if (filterData.width || filterData.height || filterData.length) {
      filters.push({
        title: 'Dimension (Max)',
        text:
          filterData.width +
          'in ' +
          filterData.height +
          'in ' +
          filterData.length +
          'in',
        filterID: ['width', 'height', 'length'],
      });
    }

    if (filterData.weight) {
      filters.push({
        title: 'Weight (Max)',
        text: filterData.weight + 'lbs',
        filterID: ['weight'],
      });
    }

    if (filterData.itemName) {
      filters.push({
        title: 'Item Name',
        text: filterData.itemName,
        filterID: ['itemName'],
      });
    }

    if (filterData.vendorName) {
      filters.push({
        title: 'Vendor Name',
        text: filterData.vendorName,
        filterID: ['vendorName'],
      });
    }

    return filters;
  };

  const handleRemoveFilter = (filter) => {
    const copyOfFilterParams = copyOfArrayOrObject(filterParams);
    filter.filterID.forEach((filterID) => {
      copyOfFilterParams[filterID] = null;
    });
    getProducts(true, 1, copyOfFilterParams);
  };

  const handleSortOptionPrice = (option) => {
    setSortOption(option);
    const copyOfFilterParams = copyOfArrayOrObject(filterParams);
    if (option === 'priceLowToHigh') {
      copyOfFilterParams.sortPrice = 'ascending';
    }

    if (option === 'priceHighToLow') {
      copyOfFilterParams.sortPrice = 'descending';
    }

    setFilterParams(copyOfFilterParams);
    getProducts(true, 1, copyOfFilterParams);
    setShowSortOptions(false);
  };

  const handleSortOption = (option) => {
    setSortOptionDetailsOption(option);
  };

  const getProducts = async (
    sort = false,
    pageQuery = 1,
    newFilterParams = {
      priceMin: 0,
      priceMax: props.selectedItem.budgetPrice,
      priceType: 'MAP',
      // projectAddress: data.project['project address'],
      projectAddress: data.project.address,
      radius: 800,
      width: null,
      height: null,
      length: null,
      weight: null,
      itemName: null,
      color: null,
      similarImageURL: null,
      vendorName: null,
      sortPrice: null,
    }
  ) => {
    setIsLoading(true);
    const copyOfNewFilteredParams = copyOfArrayOrObject(newFilterParams);
    if (!sort) {
      setSortOption(null);
      copyOfNewFilteredParams.similarImageURL = null;
      copyOfNewFilteredParams.color = null;
      copyOfNewFilteredParams.sortPrice = null;
    }

    if (copyOfNewFilteredParams.color) {
      copyOfNewFilteredParams.color = encodeURIComponent(newFilterParams.color);
    }

    if (copyOfNewFilteredParams.similarImageURL) {
      copyOfNewFilteredParams.similarImageURL = encodeURIComponent(
        newFilterParams.similarImageURL
      );
    }

    const copyOfNewFilteredParams2 = copyOfArrayOrObject(
      copyOfNewFilteredParams
    );

    copyOfNewFilteredParams2.color = newFilterParams.color;
    copyOfNewFilteredParams2.similarImageURL = newFilterParams.similarImageURL;

    setFilterParams(copyOfNewFilteredParams2);
    if (pageQuery === 1) {
      setFilteredProducts([]);
      setVendors([]);
      setTotalProductCount(null);
    }

    const queryParams = {
      ...copyOfNewFilteredParams,
      page: pageQuery,
      limit: itemsLimit,
    };

    // newFilterParams.productIDs = [];

    // if (newFilterParams.similarImageURL) {
    //   const similarImageItems = await getSimilarImageProducts(
    //     newFilterParams.similarImageURL,
    //     props.selectedItem.name
    //   );

    //   if (
    //     !similarImageItems.success ||
    //     !similarImageItems ||
    //     (similarImageItems.itemData && !similarImageItems.itemData[0]) ||
    //     similarImageItems.itemData.length === 0
    //   ) {
    //     setIsLoading(false);
    //     return;
    //   }

    //   const simProductIDs = similarImageItems.itemData.map((item) => item._id);
    //   newFilterParams.productIDs = [
    //     ...newFilterParams.productIDs,
    //     ...simProductIDs,
    //   ];
    // }

    // if (newFilterParams.color) {
    //   const itemsBasedOnHexColor = await getProductsBasedOnColor(
    //     newFilterParams.color,
    //     props.selectedItem.name
    //   );

    //   if (
    //     !itemsBasedOnHexColor.success ||
    //     !itemsBasedOnHexColor ||
    //     (itemsBasedOnHexColor.itemData && !itemsBasedOnHexColor.itemData[0]) ||
    //     itemsBasedOnHexColor.itemData.length === 0
    //   ) {
    //     setIsLoading(false);
    //     return;
    //   }

    //   const hexProductIDs = itemsBasedOnHexColor.itemData.map(
    //     (item) => item._id
    //   );

    //   newFilterParams.productIDs = [
    //     ...newFilterParams.productIDs,
    //     ...hexProductIDs,
    //   ];
    // }

    // if (newFilterParams.productIDs.length > 0) {
    //   newFilterParams.limit = newFilterParams.productIDs.length;
    //   setItemsLimit(newFilterParams.productIDs.length);
    // }

    const products = await fetchUtils(
      '/items/category' +
        props.selectedItem.name +
        '?params=' +
        JSON.stringify(queryParams),
      'GET'
    );
    if (products.success) {
      let newFilteredProducts = products.items.filteredItems;
      if (pageQuery > 1) {
        newFilteredProducts = [
          ...filteredProducts,
          ...products.items.filteredItems,
        ];
      }

      setFilteredProducts(newFilteredProducts);
      const copyOfNewFilterParams = copyOfArrayOrObject(
        copyOfNewFilteredParams2
      );
      copyOfNewFilterParams.priceMin = +products.items.lowestPrice.toFixed(2);
      setFilterParams(copyOfNewFilterParams);

      const copyOfPriceRange = copyOfArrayOrObject(priceRange);
      copyOfPriceRange.min = +products.items.lowestPrice.toFixed(2);
      setPriceRange(copyOfPriceRange);
      const copyOfRangeSize = copyOfArrayOrObject(rangeSize);
      copyOfRangeSize.min = +products.items.lowestPrice.toFixed(2);
      copyOfRangeSize.max = +products.items.highestPrice.toFixed(2);
      setRangeSize(copyOfRangeSize);
      setTotalProductCount(products.items.totalCount);
      setVendors(products.vendorsWithinRadius);
      setPage(pageQuery);
      setIsLoading(false);
    } else {
      // console.log(products.message);
      setIsLoading(false);
    }
  };

  const getAirtableProducts = () => {};

  return (
    <React.Fragment>
      {showFilters && (
        <section className='products-filters'>
          <Filters
            priceRange={priceRange}
            rangeSize={rangeSize}
            // rangeValueLabel={data.project.currency}
            rangeValueLabel={data.project.CurrencyIsoCode}
            vendors={vendors}
            filterParams={filterParams}
            isLoading={isLoading}
            handleClearFilter={() => getProducts()}
            handleFilterSearch={(p) => getProducts(false, 1, p)}
            handleCloseFilter={() => setShowFilters(false)}
          />
        </section>
      )}

      {!showAirtableProducts ? (
        <div className='products-wrapper'>
          <section
            className='products-display'
            style={{ width: showFilters ? '75%' : '100%' }}
          >
            <div className='products-display-header'>
              <div className='products-display-header-left-container'>
                <h1>{props.selectedItem.name}</h1>
                {totalProductCount &&
                  (totalProductCount > page * itemsLimit ? (
                    <p>{page * itemsLimit + ' of ' + totalProductCount} </p>
                  ) : (
                    <p>{totalProductCount + ' of ' + totalProductCount} </p>
                  ))}
              </div>

              <div className='products-display-header-right-container'>
                {!showAirtableProducts && (
                  <div
                    className='products-display-airtable'
                    onClick={() => setShowAirtableProducts(true)}
                  >
                    <SiAirtable className='products-display-airtable-icon' />
                  </div>
                )}
                {showAirtableProducts && (
                  <div
                    className='products-display-airtable'
                    onClick={() => setShowAirtableProducts(false)}
                  >
                    <SiMongodb className='products-display-airtable-icon' />
                  </div>
                )}
              </div>
            </div>
            <div className='products-display-filter-sort-row'>
              <div className='products-display-filters'>
                <div
                  className='products-display-filters-desc'
                  onClick={() => setShowFilters(true)}
                >
                  <FiFilter className='products-display-filters-desc-icon' />
                  {!showFilters && <h6>Filters</h6>}
                </div>
                {getActiveFilters(filterParams).map((filter) => {
                  return (
                    <div
                      key={uuidv4()}
                      className='products-display-filters-chip'
                    >
                      <p>{filter.text}</p>
                      {!(
                        filter.title === 'Price' || filter.title === 'Radius'
                      ) && (
                        <FiXCircle
                          className='products-display-filters-chip-icon'
                          onClick={() => handleRemoveFilter(filter)}
                        />
                      )}
                    </div>
                  );
                })}
              </div>

              <div className='products-display-sort-by'>
                <div
                  className='products-display-sort-by-title'
                  onClick={() => setShowSortOptions(true)}
                >
                  <BiSort className='products-display-sort-by-icon' />
                  <h6>Sort By</h6>
                  {sortOption && (
                    <div className='products-display-sort-by-option-selected-row'>
                      <h5>
                        {sortOption
                          .replace(/([A-Z]+)/g, ' $1')
                          .replace(/([A-Z][a-z])/g, ' $1')
                          .toUpperCase()}
                      </h5>
                      {(sortOption === 'similarImage' ||
                        sortOption === 'color') && (
                        <div
                          className='products-display-sort-by-similar-image-display'
                          style={
                            sortOption === 'similarImage'
                              ? {
                                  backgroundImage:
                                    'url(' + filterParams.similarImageURL + ')',
                                }
                              : sortOption === 'color'
                              ? { backgroundColor: filterParams.color }
                              : {}
                          }
                        ></div>
                      )}
                    </div>
                  )}
                </div>
                {showSortOptions && (
                  <div className='products-display-sort-by-options'>
                    {(sortOptionDetailsOption === 'similarImage' ||
                      sortOptionDetailsOption === 'color') && (
                      <div className='products-display-sort-by-options-more-details'>
                        <Filters
                          priceRange={priceRange}
                          rangeSize={rangeSize}
                          // rangeValueLabel={data.project.currency}
                          rangeValueLabel={data.project?.CurrencyIsoCode}
                          vendors={vendors}
                          filterParams={filterParams}
                          isLoading={isLoading}
                          isSortOption={true}
                          sortOption={sortOptionDetailsOption}
                          handleFilterSearch={(p) => {
                            setSortOption(sortOptionDetailsOption);
                            setShowSortOptions(false);
                            getProducts(true, 1, p);
                          }}
                        />
                      </div>
                    )}
                    <div className='products-display-sort-by-options-titles'>
                      <div className='products-display-close-sort-by-options-icon-container'>
                        <FiX
                          className='products-display-close-sort-by-options-icon'
                          onClick={() => setShowSortOptions(false)}
                        />
                      </div>
                      <h3
                        onClick={() =>
                          handleSortOptionPrice('priceLowToHigh', filterParams)
                        }
                      >
                        Price Low to High
                      </h3>
                      <h3
                        onClick={() =>
                          handleSortOptionPrice('priceHighToLow', filterParams)
                        }
                      >
                        Price High to Low
                      </h3>
                      <h3
                        style={
                          sortOption === 'color'
                            ? {
                                backgroundColor: '#f2f2f2',
                              }
                            : {}
                        }
                        onClick={() => handleSortOption('color')}
                      >
                        Color
                      </h3>
                      <h3
                        style={
                          sortOption === 'similarImage'
                            ? {
                                backgroundColor: '#f2f2f2',
                              }
                            : {}
                        }
                        onClick={() => handleSortOption('similarImage')}
                      >
                        Similar Image
                      </h3>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {isLoading && filteredProducts.length === 0 ? (
              <div
                className='products-display-body'
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100% ',
                  justifyContent: 'center',
                }}
              >
                <DisappearedLoading color='#ff4e24' />
              </div>
            ) : (
              <div className='products-display-body'>
                {filteredProducts.length > 0 ? (
                  filteredProducts.map((product) => {
                    return (
                      <ProductCard
                        key={uuidv4()}
                        detailsModal={(item) => {
                          setShowProductDetail(true);
                          setproductDetail(item);
                        }}
                        product={product}
                        handleSelectProduct={(p, roomType) => {
                          props.handleSelectProduct(p, roomType);
                        }}
                      />

                      // <NewProductCard
                      //   key={uuidv4()}
                      //   detailsModal={(item) => {
                      //     setShowProductDetail(true);
                      //     setproductDetail(item);
                      //   }}
                      //   product={product}
                      //   handleSelectProduct={(p, roomType) => {
                      //     props.handleSelectProduct(p, roomType);
                      //   }}
                      // />
                    );
                  })
                ) : (
                  <div
                    className='products-display-body'
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                      justifyContent: 'center',
                    }}
                  >
                    <p>No products found</p>
                  </div>
                )}
                {totalProductCount > page * itemsLimit && (
                  <MoreProductsCard
                    handleLoadMoreProducts={() =>
                      getProducts(
                        sortOption ? true : false,
                        page + 1,
                        filterParams
                      )
                    }
                    isLoading={isLoading && filteredProducts.length > 0}
                  />
                )}
              </div>
            )}
          </section>
        </div>
      ) : (
        <AirtableProducts
          selectedItem={props.selectedItem.name}
          handleSelectProduct={(p, roomType) => {
            props.handleSelectProduct(p, roomType);
          }}
          detailsModal={(item) => {
            setAirtableShowProductDetail(true);
            setairtableProductDetail(item);
          }}
          setShowAirtableProducts={() => setShowAirtableProducts(false)}
        />
      )}
      {/*!showAirtableProducts && (
        <div
          className='products-display-airtable'
          onClick={() => setShowAirtableProducts(true)}
        >
          <SiAirtable className='products-display-airtable-icon' />
        </div>
      )}
      {showAirtableProducts && (
        <div
          className='products-display-airtable'
          onClick={() => setShowAirtableProducts(false)}
        >
          <SiMongodb className='products-display-airtable-icon' />
        </div>
      )*/}
      {productDetail && (
        <PageModal
          visible={showProductDetail}
          component={<ProductModal item={productDetail} />}
          borderRadius='5px'
          padding='calc(20px + 2vw)'
          onClose={() => setShowProductDetail(false)}
        />
      )}

      {airtableProductDetail && (
        <PageModal
          visible={showAirtableProductDetail}
          component={<AirtableProductModal item={airtableProductDetail} />}
          borderRadius='5px'
          padding='calc(20px + 2vw)'
          onClose={() => setAirtableShowProductDetail(false)}
        />
      )}
    </React.Fragment>
  );
};

export default Products;

// <h6>{0 + " / " + props.selectedItem.qty}</h6>;

// {
//   sortOption === "color" && (
//     <ColorFilter
//       // color={filterParams.color}
//       handleColorSelect={(v) =>
//         handleFilterParams("color", v)
//       }
//     />
//   );
// }

// {
//   sortOption === "similarImage" && (
//     <ImageFilter
//     // similarImageURL={filterParams.similarImageURL}
//     // handleSimilarImageURL={(v) =>
//     //   handleFilterParams("similarImageURL", v)
//     // }
//     />
//   );
// }
