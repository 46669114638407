import * as React from 'react';
import { MdDragHandle } from 'react-icons/md';
import Draggable from 'react-draggable';

class Resizer extends React.Component {
  componentDidMount() {
    window.addEventListener('mouseup', this.onMouseUp, false);
  }

  componentWillUnmount() {
    window.removeEventListener('mouseup', this.onMouseUp, false);
  }

  onMouseDown = () => {
    const { id, setResizeMode } = this.props;
    setResizeMode(id, true);
  };

  onMouseUp = () => {
    const { id, isResizing, setResizeMode } = this.props;
    if (isResizing) {
      setResizeMode(id, false);
    }
  };

  handleDrag = (node) => {
    const { deltaX, deltaY, lastX, lastY } = node;
    const { id, isResizing, onResize, position, item } = this.props;

    if (isResizing) {
      onResize(
        id,
        lastX === 0 ? lastX : deltaX,
        lastY === 0 ? lastY : deltaY,
        position,
        item
      );
    }
  };

  handleStop = () => {
    this.props.onResizeStop(this.props.id);
  };

  render() {
    return (
      <Draggable
        position={{ x: 0, y: 0 }}
        defaultPosition={{ x: 0, y: 0 }}
        onMouseDown={this.onMouseDown}
        onDrag={(e, node) => this.handleDrag(node)}
        onStop={() => this.handleStop()}
      >
        <MdDragHandle
          className='resizer'
          style={{
            color: '#060E23',
            height: '15px',
          }}
        />
      </Draggable>
    );
  }
}

export default Resizer;
