import React from "react";
import "./StylesViewBox.css";
import { v4 as uuidv4 } from "uuid";
import Gap from "../../Common/Gap";
import { FiPlus } from "react-icons/fi";

const StylesViewBox = (props) => {
  return (
    <div className="styles-view-box-wrapper">
      {props.designStyles.map((style) => {
        if (!style.fields["Unnamed Moodboard"]) {
          style.fields["Unnamed Moodboard"] = style.fields.Attachments;
        }

        return (
          <div
            key={uuidv4()}
            className="styles-view-box-selected-style"
            style={{
              backgroundImage:
                "url(" + style.fields["Unnamed Moodboard"][0].url + ")",
            }}
          >
            <div className="styles-view-box-selected-style-details">
              <div
                style={{
                  transform: props.styleViewMinimized
                    ? "scale(0.5)"
                    : "scale(1)",
                }}
              >
                <h1>{style.fields.Name}</h1>
              </div>
            </div>
          </div>
        );
      })}
      <div className="styles-view-box-selected-style">
        <div
          style={{
            transform: props.styleViewMinimized ? "scale(0.5)" : "scale(1)",
          }}
        >
          <FiPlus
            className="styles-view-box-add-style-icon"
            onClick={() => props.showStyleModal()}
          />
          <h4>Add Style</h4>
        </div>
      </div>
      <Gap value="calc(5px + 0.5vw)" />
    </div>
  );
};

export default StylesViewBox;
