import React, { useEffect, useState } from 'react';
import './AddRoomMenu.css';

const AddRoomMenu = (props) => {
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [quoteItems, setQuoteItems] = useState(null);

  const handleRoomSelect = (room, action) => {
    if (action === 'reset') {
      setQuoteItems(null);
      setSelectedRoom(null);
      return;
    }

    props.activeUnit.quoteItems.forEach((quoteItem) => {
      if (quoteItem.room.description === room) {
        setQuoteItems(quoteItem.items);
      }
    });
    setSelectedRoom(room);
  };

  if (props.showMenu) {
    return (
      <>
        {!selectedRoom && (
          <ul style={{ top: '100px', left: '100px' }} className='addRoomMenu'>
            {props.activeUnit &&
              props.activeUnit.roomList.map((room, index) => {
                return (
                  <li
                    onClick={() => {
                      handleRoomSelect(room.description);
                    }}
                    className='addRoomMenu-item'
                  >
                    {room.description} {'  >'}
                  </li>
                );
              })}
          </ul>
        )}

        {selectedRoom && quoteItems && (
          <ul
            style={{ top: '100px', left: '100px', zIndex: '10000' }}
            className='addRoomMenu'
          >
            <li
              onClick={() => {
                handleRoomSelect(selectedRoom, 'reset');
              }}
              className='addRoomMenu-item'
            >
              {'<  '} {selectedRoom}
            </li>
            {quoteItems.map((item, index) => {
              return (
                <li className='addRoomMenu-item'>
                  <button className='addRoomMenu-btn'>
                    <span className='addRoomMenu-text'>
                      {item.fields['Item Name']}
                    </span>
                  </button>
                </li>
              );
            })}
          </ul>
        )}
      </>
    );
  }
  return <></>;
};

export default AddRoomMenu;
