import React, { useEffect, useState } from 'react';
import './ItemCard.css';
import { connect } from 'react-redux';
import { SiPrezi } from 'react-icons/si';
import { getCategoryCount } from '../../../../Functions/counterFn';

const UnconnectedItemCard = ({
  item,
  moodboardData,
  activeUnit,
  activeRoom,
}) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    setCount(
      getCategoryCount(
        moodboardData,
        activeUnit,
        item.name.trim(),
        activeRoom.description
      )
    );
  }, []);

  return (
    <div className='items-list-item-card'>
      <div className='items-list-item-details'>
        <SiPrezi className='items-list-item-icon' />
        <h6>{item.name.trim()}</h6>
      </div>
      <div className='items-list-item-counter'>
        <h4>{count + ' / ' + item.qty}</h4>
      </div>
    </div>
  );
};
let mapStateToProps = (state) => {
  return {
    activeRoom: state.activeRoom,
    activeUnit: state.activeUnit,
    moodboardData: state.moodboardData,
  };
};
let ItemCard = connect(mapStateToProps)(UnconnectedItemCard);
export default ItemCard;
