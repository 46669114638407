import React, { useState, useEffect } from "react";
import "./RadiusFilter.css";
import RangeSelector from "../../../../Common/RangeSelector";

const RadiusFilter = (props) => {
  const [radius, setRadius] = useState(1500);

  useEffect(() => {
    setRadius(props.radius);
  }, [props]);

  const handleRadiusRange = (value) => {
    props.handleRadiusRange(value.max);
  };

  return (
    <div className="radius-filter-wrapper">
      <RangeSelector
        label="Radius"
        labelColor="#4d4d4d"
        rangeSize={{ min: 0, max: 1500 }}
        rangeValueLabel={props.rangeValueLabel}
        minRange={0}
        maxRange={radius}
        onChange={(v) => handleRadiusRange(v)}
      />
    </div>
  );
};

export default RadiusFilter;
