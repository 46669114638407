import React, { useState, useEffect } from "react";
import "./WeightFilter.css";
import InputBox from "../../../../Common/InputBox";

const WeightFilter = (props) => {
  const [weightValue, setWeightValue] = useState("");

  useEffect(() => {
    setWeightValue(props.weight);
  }, []);

  const handleWeightInput = (event) => {
    setWeightValue(event.target.value);
    props.handleWeight(event.target.value);
  };

  return (
    <div className="weight-filter-wrapper">
      <h6 className="weight-filter-label">Weight</h6>
      <div className="weight-filter-input">
        <div style={{ width: "31%" }}>
          <InputBox
            name="weight"
            type="text"
            background="#FFFFFF"
            border="1px solid #DCE1EB"
            activeBorder="1px solid #242627"
            borderRadius="5px"
            width="100%"
            height="calc(20px + 1vw)"
            padding="0 calc(5px + 0.5vw)"
            margin="0"
            fontSize="calc(8px + 0.5vw)"
            placeholder="Weight"
            suffixText={props.rangeValueLabel}
            value={weightValue}
            onChange={(e) => handleWeightInput(e)}
          />
        </div>
      </div>
    </div>
  );
};

export default WeightFilter;
