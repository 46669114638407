import React from "react";
import "./MoreProductsCard.css";
import { FiPlus } from "react-icons/fi";
import { DisappearedLoading } from "react-loadingg";

const MoreProductsCard = (props) => {
  return (
    <div
      className="product-card-wrapper"
      onClick={() => !props.isLoading && props.handleLoadMoreProducts()}
    >
      <div className="product-card-wrapper-container">
        {props.isLoading ? (
          <div className="more-products-card-container">
            <DisappearedLoading color="#ff4e24" />
          </div>
        ) : (
          <div className="more-products-card-container">
            <FiPlus className="product-card-more-products-icon" />
            <h4>Load More</h4>
          </div>
        )}
      </div>
    </div>
  );
};

export default MoreProductsCard;
