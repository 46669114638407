import React, { useState } from 'react';
import { TransverseLoading } from 'react-loadingg';
import CircularProgress from '@material-ui/core/CircularProgress';

let Button = (props) => {
  const [clickEffect, setClickEffect] = useState(false);
  let style = {
    main: {
      textAlign: props.textAlign ? props.textAlign : 'center',
      background: props.background ? props.background : 'transparent',
      width: props.width,
      height: props.height,
      color: props.color,
      fontSize: props.fontSize,
      fontWeight: props.fontWeight,
      border: props.border ? props.border : 'none',
      borderRadius: props.borderRadius,
      margin: props.margin,
      padding: props.padding,
      opacity: (!props.enabled || clickEffect) && 0.5,
      cursor: !props.enabled && 'not-allowed',
      display: props.display ? props.display : '',
    },
    iconContainer: {
      minHeight: props.iconSize ? props.iconSize : '10px',
      minWidth: props.iconSize ? props.iconSize : '10px',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      margin: props.iconMargin,
    },
    suffixIcon: {
      margin: props.suffixIconMargin,
    },

    prefixIcon: {
      margin: props.prefixIconMargin,
    },
    loader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: props.width,
      height: props.height,
    },
  };

  const buttonClicked = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setClickEffect(true);
    props.enabled && props.onClick && props.onClick(event);
    setTimeout(() => {
      setClickEffect(false);
    }, 100);
  };

  return (
    <button name={props.name} style={style.main} onClick={buttonClicked}>
      {props.prefixIcon && (
        <div
          style={{
            backgroundImage: 'url(' + props.prefixIcon + ')',
            ...style.iconContainer,
            ...style.suffixIcon,
          }}
        ></div>
      )}
      {props.prefixIconComponent && (
        <div
          style={{
            ...style.iconContainer,
            ...style.suffixIcon,
          }}
        >
          {props.prefixIconComponent}
        </div>
      )}
      {!props.loading ? (
        <span>{props.label}</span>
      ) : (
        <CircularProgress size={15} color={props.loadingColor} />
      )}
      {props.suffixIcon && (
        <div
          style={{
            backgroundImage: 'url(' + props.suffixIcon + ')',
            ...style.iconContainer,
            ...style.prefixIcon,
          }}
        ></div>
      )}
    </button>
  );
};

export default Button;
