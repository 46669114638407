import React, { useState } from 'react';
import SearchBox from '../../Filters/SearchBox/SearchBox';
import Button from '../../../../Common/Button';
import Gap from '../../../../Common/Gap';
import { FiX } from 'react-icons/fi';

const AirtableFilters = (props) => {
  const [search, setSearch] = useState('');
  const [projectSearch, setProjectSearch] = useState('');

  const handleSearch = () => {
    if (search != '') {
      props.handleSearch(search);
    }
    if (projectSearch != '') {
      props.handleSearch(projectSearch, 'projectSearch');
    }
  };

  return (
    <div className='airtable-filters-wrapper'>
      <div className='filters-header'>
        <div className='filters-header-top'>
          <h4>Filters</h4>
          <FiX
            className='filters-close-icon'
            onClick={() => props.handleCloseFilter()}
          />
        </div>
        <SearchBox
          searchParams={search}
          handleSearchInput={(v) => {
            setProjectSearch('');
            setSearch(v);
          }}
        />
        <Gap value={'15px'} />
        <SearchBox
          searchParams={projectSearch}
          placeholder='Search by project name'
          handleSearchInput={(v) => {
            setSearch('');
            setProjectSearch(v);
          }}
        />
      </div>
      <div className='filters-search-button'>
        <Button
          name='search'
          background='#242627'
          borderRadius='5px'
          width='100%'
          enabled={true}
          height='calc(20px + 2vw)'
          padding='0 calc(10px + 1vw)'
          onClick={() => handleSearch()}
          color='#FFFFFF'
          label='Search'
          fontSize='calc(8px + 0.5vw)'
        />
      </div>
    </div>
  );
};

export default AirtableFilters;
