import React, { useState } from 'react';
import './DocumentsViewBox.css';
import { FaPlusCircle } from 'react-icons/fa';
import AdditionalInfo from './AdditionalInfo/AdditionalInfo';
import copyOfArrayOrObject from '../../../Functions/copyOfArrayOrObject';
import fileUploadUtils from '../../../Functions/fileUploadUtils';
import fetchUtils from '../../../Functions/fetchUtils';
import data from '../../../Data';
import setProject from '../../../Functions/setProject';
import setProjectFilesMetaData from '../../../Functions/setProjectFilesMetaData';

const DocumentsViewBox = (props) => {
  const [uploadingFile, setUploadingFile] = useState(false);
  const [deletingFile, setDeletingFile] = useState(false);

  const handleFileUpload = async (event) => {
    setUploadingFile(true);
    let formData = new FormData();
    for (let i = 0; i < Object.keys(event.target.files).length; i++) {
      formData.append('uploaded-files', event.target.files[i]);
    }
    formData.append('project', JSON.stringify(data.project));
    // let response = await fileUploadUtils('/pipedrive/files', 'POST', formData);
    // if (response.success) {
    //   let projectFilesMetaData = await fetchUtils(
    //     '/pipedrive/files/' + data.project.id,
    //     'GET',
    //     ''
    //   );
    //   if (!projectFilesMetaData.success) {
    //     setUploadingFile(false);
    //     return;
    //   }

    //   setProjectFilesMetaData(projectFilesMetaData.files);
    //   setUploadingFile(false);
    // } else {
    //   console.log(response.message);
    //   setUploadingFile(false);
    // }
  };

  // const handleDeleteFile = async (index) => {
  //   setDeletingFile(true);
  //   const fileID = data.project.filesMetaData[index].id;
  //   let response = await fetchUtils('/pipedrive/files/' + fileID, 'DELETE');
  //   if (response.success) {
  //     let projectFilesMetaData = await fetchUtils(
  //       '/pipedrive/files/' + data.project.id,
  //       'GET',
  //       ''
  //     );
  //     if (!projectFilesMetaData.success) {
  //       console.log(projectFilesMetaData.message);
  //       setDeletingFile(false);
  //       return;
  //     }

  //     const copyOfProject = copyOfArrayOrObject(data.project);
  //     copyOfProject.filesMetaData = projectFilesMetaData.files;

  //     setProject(copyOfProject);
  //     setDeletingFile(false);
  //   } else {
  //     console.log(response.message);
  //     setDeletingFile(false);
  //   }
  // };

  // const setFileBase64URL = (fileID, url) => {
  //   const fileBase64URLsCopy = copyOfArrayOrObject(props.fileBase64URLs);
  //   fileBase64URLsCopy[fileID] = url;

  //   props.dispatch({
  //     type: "set-fileBase64URLs",
  //     value: fileBase64URLsCopy,
  //   });
  // };

  return (
    <div className='documents-view-box-wrapper'>
      <div className='documents-view-box-title-header'>
        <div className='documents-view-box-row-item1'></div>
        <h5
          className='documents-view-box-row-item2'
          style={{
            fontSize: props.documentsViewMinimized
              ? 'calc(5px + 0.3vw)'
              : 'calc(5px + 0.5vw)',
          }}
        >
          Name
        </h5>
        <h5
          className='documents-view-box-row-item3'
          style={{
            fontSize: props.documentsViewMinimized
              ? 'calc(5px + 0.3vw)'
              : 'calc(5px + 0.5vw)',
          }}
        >
          File Type
        </h5>
      </div>
      {/* <div className='documents-view-box-body'>
        <AdditionalInfo
          projectFiles={data.projectFilesMetaData}
          fileBase64URLs={data.fileBase64URLs}
          uploadingFile={uploadingFile}
          deletingFile={deletingFile}
          deleteFile={(i) => handleDeleteFile(i)}
        />
      </div> */}
      <div className='documents-view-box-add-button-container'>
        <FaPlusCircle className='documents-view-box-add-button-icon' />
        <input
          disabled={deletingFile}
          type='file'
          onChange={(e) => handleFileUpload(e)}
          multiple
        />
      </div>
    </div>
  );
};

export default DocumentsViewBox;
