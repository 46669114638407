const Data = {
  user: null,
  project: null,
  quote: null,
  activeRoom: null,
  activeUnit: null,
  designStyles: null,
  projectFilesMetaData: [],
  projectNotes: [],
  designElementCategories: [],
  airtableCategory: null,
  airtableProjects: null,
};

export default Data;
