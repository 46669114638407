import copyOfArrayOrObject from '../Functions/copyOfArrayOrObject';

const ReduxReducer = (state, action) => {
  if (action.type === 'set-user') {
    return {
      ...state,
      user: action.value,
    };
  }

  if (action.type === 'set-activeRoom') {
    return {
      ...state,
      activeRoom: action.value,
    };
  }

  if (action.type === 'set-activeTab') {
    return {
      ...state,
      activeRoom: action.value,
    };
  }

  if (action.type === 'set-activeUnit') {
    return {
      ...state,
      activeUnit: action.value,
    };
  }
  if (action.type === 'initialize-moodboardData') {
    return {
      ...state,
      moodboardData: action.value,
      revisionNum: action.revisionNum,
    };
  }

  if (action.type === 'set-moodboardData') {
    let unitName = action.unit;
    let tmp = copyOfArrayOrObject(state.moodboardData);
    tmp[unitName] = action.value;
    return {
      ...state,
      moodboardData: tmp,
    };
  }

  return state;
};

export default ReduxReducer;
