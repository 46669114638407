import React, { useState, useEffect } from 'react';
import './SearchBox.css';
import { FiSearch } from 'react-icons/fi';
import InputBox from '../../../../Common/InputBox';

const SearchBox = (props) => {
  const [searchInput, setSearchInput] = useState(props.searchParams);

  useEffect(() => {
    setSearchInput(props.searchParams);
  }, [props]);

  const handleSearchInput = (event) => {
    setSearchInput(event.target.value);
    props.handleSearchInput(event.target.value);
  };

  return (
    <div className='search-wrapper'>
      <div
        className='search-box-wrapper'
        style={{
          border: searchInput ? '1px solid #242627' : '1px solid #DCE1EB',
        }}
      >
        <FiSearch className='search-icon' />
        <div style={{ flex: '1' }}>
          <InputBox
            name='location'
            type='text'
            background='transparent'
            borderRadius='5px'
            width='100%'
            height='calc(20px + 1vw)'
            padding='0 calc(2px + 1vw)'
            margin='0'
            color='#242627'
            fontSize='calc(8px + 0.5vw)'
            placeholder={
              props.placeholder ? props.placeholder : 'Search by product name'
            }
            value={searchInput}
            onChange={(e) => handleSearchInput(e)}
          />
        </div>
      </div>
    </div>
  );
};

export default SearchBox;
