const getUnitTotal = (moodboardData, unit) => {
  let selectedUnit = '';
  let total = 0;
  if (moodboardData && unit && moodboardData[unit.unit.fields['Item Name']]) {
    selectedUnit = unit.unit.fields['Item Name'];
    Object.keys(moodboardData[selectedUnit]).map((room, index) => {
      if (Array.isArray(moodboardData[selectedUnit][room])) {
        moodboardData[selectedUnit][room].map((tab, index) => {
          if (tab.activeTab === true) {
            moodboardData[selectedUnit][room][index].moodboard.map((item) => {
              if (!item.bkgImageURL && item.tradePrice) {
                total += item.tradePrice;
              }
              return total;
            });
          }
        });
      }
    });
    return total;
  }
};

const getProjectTotal = (moodboardData) => {
  let total = 0;
  if (moodboardData) {
    Object.keys(moodboardData).map((selectedUnit, index) => {
      Object.keys(moodboardData[selectedUnit]).map((room, index) => {
        if (Array.isArray(moodboardData[selectedUnit][room])) {
          moodboardData[selectedUnit][room].map((tab, index) => {
            if (tab.activeTab === true) {
              moodboardData[selectedUnit][room][index].moodboard.map((item) => {
                if (!item.bkgImageURL && item.tradePrice) {
                  total += item.tradePrice;
                }
                return total;
              });
            }
          });
        }
      });
    });
    return total;
  }
};

export { getUnitTotal, getProjectTotal };
