import React, { useEffect, useState } from 'react';
import './DesignElements.css';
import data from '../../../Data';
import { DisappearedLoading } from 'react-loadingg';
import fetchUtils from '../../../Functions/fetchUtils';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import DesignElementCard from './DesignElementCard/DesignElementCard';
import DesignElementModal from './DesignElementModal/DesignElementModal';
import { AiOutlinePlus } from 'react-icons/ai';
import PageModal from '../../Common/PageModal';
import './DesignElementCard/DesignElementCard.css';

const UnconnectedDesignElements = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [designElementCategories, setDesignElementCategories] = useState([]);
  const [designElements, setDesignElements] = useState([]);
  const [showAddDesignElement, setShowAddDesignElement] = useState(false);
  const [
    activeDesignElementCategory,
    setActiveDesignElementCategory,
  ] = useState('');

  useEffect(() => {
    setDesignElementCategories(
      data.designElementCategories.map((element) => element.title)
    );
    getDesignElements();
  }, []);

  const addDesignElement = async (data) => {
    setIsLoading(true);

    let formdata = new FormData();
    formdata.append('description', data.description);
    formdata.append('title', data.title);
    formdata.append('file', data.file);

    // const payload = await fetchUtils('/moodboard-property/', 'POST', formdata);
    let response = await fetch(`/moodboard-property`, {
      method: 'POST',
      body: formdata,
    });

    let responseBody = await response.text();
    let payload = JSON.parse(responseBody);

    if (payload.success) {
      getDesignElements();
      setShowAddDesignElement(false);
    }
  };

  const getDesignElements = async (title = 'All') => {
    // if (title === activeDesignElementCategory) {
    //   setIsLoading(false);
    //   return;
    // }
    const response = await fetchUtils('/moodboard-property/' + title, 'GET');
    if (response.success) {
      setDesignElements(response.moodboardPropertyData.reverse());
      setActiveDesignElementCategory(title);
      setIsLoading(false);
    } else {
      // console.log(response.message);
      setIsLoading(false);
    }
  };

  const handleDelete = async (id) => {
    setIsLoading(true);

    let response = await fetch(`/moodboard-property/${id}`, {
      method: 'delete',
      credentials: 'include',
    });
    let responseBody = await response.text();
    let payload = JSON.parse(responseBody);

    if (payload.success) {
      getDesignElements();
    }
  };

  return (
    <React.Fragment>
      <section className='design-elements-container'>
        <div className='design-elements-wrapper' style={{ width: '100%' }}>
          <div className='design-elements-display-header'>
            <h1>DESIGN ELEMENTS</h1>
          </div>
          <div className='design-elements-display-categories'>
            {designElementCategories.length > 1 &&
              ['All', ...designElementCategories].map((category) => {
                return (
                  <div
                    className='design-elements-display-category'
                    style={
                      activeDesignElementCategory.toUpperCase() ===
                      category.toUpperCase()
                        ? {
                            backgroundColor: '#242627',
                            color: '#ffffff',
                            fontWeight: 'bold',
                          }
                        : {}
                    }
                    onClick={() => getDesignElements(category)}
                  >
                    {category.toUpperCase()}
                  </div>
                );
              })}
          </div>
          {isLoading ? (
            <div
              className='design-elements-display-body'
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '100% ',
                justifyContent: 'center',
              }}
            >
              <DisappearedLoading color='#ff4e24' />
            </div>
          ) : (
            <div className='design-elements-display-body'>
              <div
                className='design-element-card-wrapper'
                onClick={() => setShowAddDesignElement(!showAddDesignElement)}
              >
                <div className='design-element-card-wrapper-container'>
                  <div className='design-element-card-design-element-image'>
                    <AiOutlinePlus size={80} />
                  </div>
                  <div className='design-element-card-wrapper-container-overlay'></div>
                </div>
              </div>
              {designElements.map((element) => {
                return (
                  <DesignElementCard
                    key={uuidv4()}
                    element={element}
                    handleSelectElement={() => {
                      props.handleSelectedDesignElement(
                        element,
                        props.activeRoom.description
                      );
                    }}
                    handleDelete={(id) => handleDelete(id)}
                  />
                );
              })}
              {designElements.length === 0 && (
                <div
                  className='design-elements-display-body'
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    justifyContent: 'center',
                  }}
                >
                  <p>No design elements found</p>
                </div>
              )}
            </div>
          )}
        </div>
      </section>
      <PageModal
        visible={showAddDesignElement}
        component={
          <DesignElementModal
            addDesignElement={(data) => addDesignElement(data)}
          />
        }
        borderRadius='5px'
        padding='calc(20px + 2vw)'
        onClose={() => setShowAddDesignElement(false)}
      />
    </React.Fragment>
  );
};

let mapStateToProps = (state) => {
  return {
    activeRoom: state.activeRoom,
  };
};
let DesignElements = connect(mapStateToProps)(UnconnectedDesignElements);
export default DesignElements;
