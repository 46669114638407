import React, { useState } from 'react';
import './NavBar.css';
import FulhausIcon from '../../Logo/fulhaus-logo.png';
import projectData from '../../../Data';
import { connect } from 'react-redux';
import Button from '../../Common/Button';
import fetchUtils from '../../../Functions/fetchUtils';
import { useAlert } from 'react-alert';
import copyOfArrayOrObject from '../../../Functions/copyOfArrayOrObject';
import PageModal from '../PageModal';
import OverrideModal from './OverrideModal/OverrideModal';
import getUpdatedQuoteDataWithMoodboardImages from './FinalizeFn';
import DropDownComponent from '../DropDownComponent';
import data from '../../../Data';
import StatsDropDown from './StatsDropDown/StatsDropDown';
import DownIcon from '../../../Icons/chevron_down.png';

const UnconnectedNavBar = (props) => {
  const [airtableLoading, setAirtableLoading] = useState(false);
  const [showOverrideModal, setShowOverrideModal] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const alert = useAlert();

  const handleSave = async (finalized) => {
    setSaveLoading(true);
    if (props.moodboardData) {
      let data = {
        quoteID: projectData.quote.quoteID.quoteID,
        // title: projectData.quote.project.title,
        title: projectData.project.Name,
        moodboardData: props.moodboardData,
        finalized: finalized,
        // user: props.user,
      };
      let payload = await fetchUtils(
        `/moodboard/${projectData.quote.quoteID.quoteID}`,
        'PATCH',
        data
      );
      if (payload.success) {
        setSaveLoading(false);
        alert.show(<h3 style={{ color: '#242627' }}>{payload.message}</h3>);
      } else {
        setSaveLoading(false);
        alert.show(<h3 style={{ color: '#242627' }}>{payload.message}</h3>);
      }
    }
  };

  const handleOverrideModal = (type) => {
    if (type === 'override') {
      setShowOverrideModal(!showOverrideModal);
      setAirtableLoading(false);
    }
  };

  const finalizeMoodboard = async () => {
    setAirtableLoading(true);
    let finalized = await getAirtableData();
    if (!finalized) {
      let data = {
        quoteID: projectData.quote.quoteID.quoteID,
        // projectTitle: projectData.quote.project.title,
         projectTitle: projectData.project.Name,
        moodboardData: props.moodboardData,
        // projectCurrency: projectData.quote.projectCurrency,
        projectCurrency: projectData.project.CurrencyIsoCode,
        user: props.user,
      };
      let payload = await fetchUtils(`/airtable/moodboard/`, 'POST', data);
      if (payload.success) {
        sendQuoteDataWithImgs();
        sendProjectInfoToAirtable();
        setAirtableLoading(false);
        alert.show(<h3 style={{ color: '#242627' }}>SUCCESS</h3>);
      } else {
        setAirtableLoading(false);
        alert.show(<h3 style={{ color: '#242627' }}>ERROR</h3>);
      }
    } else {
      handleOverrideModal('override');
    }
  };

  const getAirtableData = () => {
    let quoteID = projectData.quote.quoteID.quoteID;
    // let projectCurrency = projectData.quote.projectCurrency;
    let projectCurrency = projectData.project.CurrencyIsoCode;

    let params = {
      quoteID: quoteID,
      projectCurrency: projectCurrency,
    };
    return new Promise(async (resolve, reject) => {
      let payload = await fetchUtils(
        `/airtable/moodboard/${JSON.stringify(params)}`,
        'GET',
        params
      );
      if (payload.success) {
        resolve(true);
      } else {
        resolve(false);
      }
    });
  };

  const overrideAirtable = async () => {
    setAirtableLoading(true);
    handleOverrideModal('override');
    // let projectTitle = projectData.quote.project.title;
    let projectTitle = projectData.project.Name;
    let quoteID = projectData.quote.quoteID.quoteID;
    let data = {
      quoteID: quoteID,
      projectTitle: projectTitle,
      moodboardData: props.moodboardData,
      // projectCurrency: projectData.quote.projectCurrency,
       projectCurrency: projectData.project.CurrencyIsoCode,
    };
    let payload = await fetchUtils(
      `/airtable/moodboard/${quoteID}`,
      'PATCH',
      data
    );
    if (payload.success) {
      finalizeMoodboard();
      setAirtableLoading(false);
    } else {
      alert.show('ERROR');
      setAirtableLoading(false);
    }
  };

  const sendQuoteDataWithImgs = async () => {
    const updatedQuoteDataWithMoodboardImages = getUpdatedQuoteDataWithMoodboardImages(
      props.moodboardData
    );
    const quoteIDObject = projectData.quote.quoteID;
    let data = {
      quoteIDObject: quoteIDObject,
      quoteData: updatedQuoteDataWithMoodboardImages,
    };

    let payload = await fetchUtils(`/quotes/update-quoteData`, 'POST', data);
    if (payload.success) {
      // console.log('Sent to Quote', payload);
    }
  };

  const sendProjectInfoToAirtable = async () => {
    let params = {
      // 'Listing ID': projectData.quote.project.title,
      'Listing ID': projectData.project.Name,
      // 'Project Address': projectData.quote['project address']
      //   ? projectData.quote['project address']
      //   : '',
      'Project Address': projectData.project?.address ?? "",
      
      'Quote ID': projectData.quote.quoteID.quoteID,
      // Creator: projectData?.quote?.project?.Creator?.name ?? projectData?.quote?.project?.creator?.name,
      Creator: projectData?.project?.userDetails?.Name,
      // Currency: projectData?.quote?.projectCurrency,
      Currency: projectData?.project?.CurrencyIsoCode,
      // 'Start Date': projectData.quote.project['Deal created'],
      'Start Date': projectData.project.CreatedDate,
      'Client Docs': null,
    };
    let payload = await fetchUtils(`/airtable/projects/`, 'PATCH', params);
    if (payload.success) {
      // console.log('Project data ', payload);
    } else {
      // console.log('Project data ', payload);
    }
  };

  const redirect = () => {
    let url = `https://apps.fulhaus.com/`;
    var win = window.open(url, '_self');
    win.focus();
  };

  return (
    <div className='navbar-wrapper'>
      <div className='navbar-left-section'>
        <div
          className='navbar-logo'
          onClick={redirect}
          style={{ backgroundImage: 'url(' + FulhausIcon + ')' }}
        ></div>
        <div className='navbar-app-name'>
          <h6>Design</h6>
          <h6>Builder</h6>
        </div>
        <div className='navbar-project-details'>
          <h1>{props.projectTitle}</h1>
          <p>{props.projectAddress}</p>
          <h5>{props.projectBudget}</h5>
          <DropDownComponent
            label='Stats'
            component={<StatsDropDown />}
            componentContainerFloat={'right'}
            icon={DownIcon}
            iconSize='10px'
            iconColorInvert={true}
            labelAlign='flex-start'
            padding='5px 0'
            labelHeight='25px'
          />
        </div>
      </div>
      {props.actionButtons && (
        <>
          <div className='navbar-right-section'>
            <Button
              name='save'
              background='#FFFFFF'
              borderRadius='3px'
              border='1px solid #242627'
              enabled={true}
              width='120px'
              height='65px'
              height='calc(10px + 1vw)'
              padding='0 calc(10px + 1vw)'
              margin=' 0 10px 0'
              onClick={() => handleSave(false)}
              loading={saveLoading}
              loadingColor={'#242627'}
              color='#242627'
              label='Save'
              fontSize='calc(8px + 0.5vw)'
            />
            <Button
              name='save'
              background='#242627'
              borderRadius='3px'
              width='120px'
              height='65px'
              border='1px solid #242627'
              enabled={true}
              height='calc(10px + 1vw)'
              padding='0 calc(10px + 1vw)'
              onClick={() => finalizeMoodboard()}
              loading={airtableLoading}
              loadingColor={'white'}
              color='#FFFFFF'
              label='Finalize'
              fontSize='calc(8px + 0.5vw)'
            />
          </div>
          {showOverrideModal && (
            <PageModal
              visible={showOverrideModal}
              component={
                <OverrideModal
                  overrideAirtable={() => overrideAirtable()}
                  handleOverrideModal={() => handleOverrideModal('override')}
                />
              }
              borderRadius='5px'
              padding='calc(20px + 2vw)'
              onClose={() => handleOverrideModal('override')}
            />
          )}
        </>
      )}
    </div>
  );
};

let mapStateToProps = (state) => {
  return {
    activeRoom: state.activeRoom,
    moodboardData: state.moodboardData,
  };
};
let NavBar = connect(mapStateToProps)(UnconnectedNavBar);
export default NavBar;
