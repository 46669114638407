import React, { useEffect } from 'react';

import useContextMenu from '../../../../Hooks/UseContextMenu';

const Menu = (props) => {
  const { xPos, yPos, menu } = useContextMenu(props.outerRef);

  useEffect(() => {
    props.showMenu(menu);
  }, [menu]);

  if (menu) {
    return (
      <ul
        style={{ top: yPos, left: xPos, zIndex: '10000' }}
        className='menu menu-show'
      >
        <li className='menu-item'>
          <button onClick={props.flipImg} className='menu-btn'>
            <span className='menu-text'> Flip image</span>
          </button>
        </li>
        <li className='menu-item'>
          <button className='menu-btn' onClick={props.showRotateImg}>
            <span className='menu-text'>Rotate image</span>
          </button>
        </li>
        <li className='menu-item'>
          <button className='menu-btn' onClick={props.cropImage}>
            <span className='menu-text'>Crop image</span>
          </button>
        </li>
        {!props.item.bkgImageURL && (
          <li className='menu-item'>
            <button
              className='menu-btn'
              onClick={() => props.editProduct(props.item, props.roomType)}
            >
              <span className='menu-text'>Edit</span>
            </button>
          </li>
        )}
        {!props.item.bkgImageURL && (
          <li className='menu-item'>
            <button className='menu-btn' onClick={props.setAspectRatio}>
              <span className='menu-text'>
                {props.item && props.item.moodboardMeta.aspectRatio
                  ? 'Unlock aspect ratio'
                  : 'Lock aspect ratio'}
              </span>
            </button>
          </li>
        )}
        {props.item.vendorName && (
          <li className='menu-item'>
            <button
              className='menu-btn'
              onClick={() => {
                props.removeBG(props.item, props.roomType);
              }}
            >
              <span className='menu-text'>Remove background</span>
            </button>
          </li>
        )}
        {props.item.vendorName && (
          <li className='menu-item'>
            <button
              className='menu-btn'
              onClick={() => {
                !props.item.airtableProduct
                  ? props.details(props.item)
                  : props.airtableDetails(props.item);
              }}
            >
              <span className='menu-text'> Details</span>
            </button>
          </li>
        )}

        <li className='menu-item menu-item-submenu'>
          <button className='menu-btn'>
            <i className='fa fa-users'></i>
            <span className='menu-text'>Order</span>
          </button>
          <ul className='menu'>
            <li className='menu-item'>
              <button className='menu-btn' onClick={props.bringToFront}>
                <span className='menu-text'>Bring to front </span>
              </button>
            </li>
            <li className='menu-item '>
              <button className='menu-btn' onClick={props.bringForward}>
                <span className='menu-text'>Bring foward</span>
              </button>
            </li>
            <li className='menu-item '>
              <button className='menu-btn' onClick={props.sendBackward}>
                <span className='menu-text'>Send backward</span>
              </button>
            </li>
            <li className='menu-item'>
              <button className='menu-btn' onClick={props.sendToBack}>
                <span className='menu-text'>Send to back</span>
              </button>
            </li>
          </ul>
        </li>
        {props.imgArray && (
          <li className='menu-item menu-item-submenu'>
            <button className='menu-btn'>
              <i className='fa fa-users'></i>
              <span className='menu-text'>Images</span>
            </button>
            <ul className='menu'>
              {props.imgArray.map((img, index) => {
                return (
                  <li className='menu-item'>
                    <button
                      style={{
                        backgroundColor:
                          img === props.item.displayedImage ? 'green' : '',
                      }}
                      onClick={() => {
                        props.setImg(index, props.item.moodboardId);
                      }}
                      className='menu-btn'
                    >
                      <i className='fa fa-comment'></i>
                      <span className='menu-text'>
                        {' '}
                        {`Image ${index + 1} ${
                          img.includes('moodboard-image.s3') ? '*' : ''
                        }`}{' '}
                      </span>
                    </button>
                  </li>
                );
              })}
            </ul>
          </li>
        )}
        <li className='menu-separator'></li>

        <li className='menu-item'>
          <button
            className='menu-btn'
            onClick={() => {
              props.delete(props.item, props.roomType);
            }}
          >
            <i className='fa fa-trash'></i>
            <span className='menu-text'>Delete</span>
          </button>
        </li>
      </ul>
    );
  }
  return <></>;
};

export default Menu;
