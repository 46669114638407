import React from 'react';
import Button from '../../Button';
import './OverrideModal.css';

export default function OverrideModal(props) {
  return (
    <div>
      <h1>Override previous data?</h1>
      <div className='overrideModal-button-container'>
        <Button
          name='yes'
          background='#FFFFFF'
          borderRadius='3px'
          border='1px solid #242627'
          enabled={true}
          height='calc(20px + 1vw)'
          padding='0 calc(10px + 1vw)'
          margin=' 0 10px 0'
          onClick={props.overrideAirtable}
          color='#242627'
          fontSize='calc(8px + 0.5vw)'
          label='Yes'
        />
        <Button
          name='no'
          background='#242627'
          borderRadius='3px'
          border='1px solid #242627'
          enabled={true}
          height='calc(20px + 1vw)'
          padding='0 calc(10px + 1vw)'
          enabled={true}
          onClick={props.handleModalClose}
          color='#FFFFFF'
          label='Cancel'
          fontSize='calc(8px + 0.5vw)'
        />
      </div>
    </div>
  );
}
