import React, { useEffect, useState, useRef } from "react";
import Draggable from "react-draggable";
import { maxBy } from "lodash";
import { connect } from "react-redux";
import produce from "immer";
import Resizer from "../ImageTools/Resizer";
import { MIN_DIMENSION, TOP_BOUND } from "../../../constants";
import ImageOnCanvas from "../ImageTools/ImageOnCanvas";
import { v4 as uuidv4 } from "uuid";
import Text from "./Text";
import PageModal from "../../Common/PageModal";
import "../css/Canvas.css";
import CanvasMenu from "../contextMenu/canvasMenu/CanvasMenu";
import copyOfArrayOrObject from "../../../Functions/copyOfArrayOrObject";
import useWindowDimensions from "../../../Hooks/useWindowDimensions";
import BackgroundChange from "../inputComponents/BackgroundChange";
import { debounce } from "lodash";

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const UnconnectedCanvas = (props) => {
  const canvasRef = useRef(null);
  const { width, height } = useWindowDimensions();
  const [state, setState] = useState({
    list: props.list,
    roomType: props.activeRoom && props.activeRoom.description,
  });
  const [showProductDetail, setShowProductDetail] = useState(false);
  const [productDetail, setProductDetail] = useState(null);
  const [showItemMenu, setShowItemMenu] = useState(false);
  const [showBackgroundChange, setShowBackgroundChange] = useState(false);
  const [multiSelected, setMultiSelected] = useState([]);
  const [selectedTab, setSelectedTab] = useState(null);
  const preList = usePrevious(props.list);

  useEffect(() => {
    if (props.list) {
      setState((state) =>
        produce(state, (st) => {
          st.roomType = props.activeRoom.description;
        })
      );
      let index = props.list[props.activeRoom.description].findIndex(
        (tab) => tab.activeTab === true
      );
      let tab = props.list[props.activeRoom.description][index];
      setSelectedTab(tab);
    }
  }, [props.activeRoom]);

  useEffect(() => {
    if (preList !== props.list) {
      setState((state) =>
        produce(state, (st) => {
          st.list = props.list;
        })
      );

      let index = props.list[state.roomType].findIndex(
        (tab) => tab.activeTab === true
      );
      let tab = props.list[state.roomType][index];
      setSelectedTab(tab);
    }
  }, [props.list]);

  useEffect(() => {
    let index = props.list[state.roomType].findIndex(
      (tab) => tab.activeTab === true
    );
    let tab = props.list[state.roomType][index];
    setSelectedTab(tab);
  }, []);

  const handleStart = (item, evt) => {
    if (!item || item.isResizing) {
      return false;
    }
    const targetList = selectedTab.moodboard;
    const itemMaxZ = maxBy(
      targetList,
      (e) => {
        return e.moodboardMeta.position.zIndex;
      },
      "zIndex"
    );
    if (!itemMaxZ) {
      return;
    }
    if (
      itemMaxZ &&
      itemMaxZ.moodboardMeta.position.zIndex !==
        item.moodboardMeta.position.zIndex
    ) {
      const index = targetList.findIndex(
        (i) => i.moodboardId === item.moodboardId
      );
      const newList = [...targetList];
      newList[index] = {
        ...item,
        moodboardMeta: {
          position: {
            top: item.moodboardMeta.position.top,
            left: item.moodboardMeta.position.left,
            zIndex: itemMaxZ.moodboardMeta.position.zIndex + 1,
          },
          dimensions: {
            width: item.moodboardMeta.dimensions.width,
            height: item.moodboardMeta.dimensions.height,
          },
          rotation: item.moodboardMeta.rotation,
          flipImg: item.moodboardMeta.flipImg,
          aspectRatio: item.moodboardMeta.aspectRatio,
        },
      };
      let newTab = selectedTab;
      newTab.moodboard = newList;
      props.onChange(newTab, state.roomType);
    }
  };

  const handleStop = (item, node) => {
    const { x, y } = node;
    if (
      x === item.moodboardMeta.position.left &&
      y === item.moodboardMeta.position.top
    ) {
      return;
    }
    let newTab = selectedTab;
    let newList = copyOfArrayOrObject(selectedTab.moodboard);
    let modifiedItem = {
      ...item,
      moodboardMeta: {
        ...item.moodboardMeta,
        position: {
          ...item.moodboardMeta.position,
          top: y,
          left: x,
        },
      },
    };
    let filterList = newList.filter((i) => i.moodboardId !== item.moodboardId);
    filterList.push(modifiedItem);
    if (item && modifiedItem) {
      newTab.moodboard = filterList;
      props.onChange(newTab, state.roomType);
    }
  };

  const handleResizeStop = (id) => {
    const item = selectedTab.moodboard.find((i) => i.moodboardId === id);

    if (item) {
      props.onChange(selectedTab, state.roomType);
    }
  };

  const handleResizing = (id, deltaX, deltaY, dims, product) => {
    const targetList = selectedTab.moodboard;
    const index = targetList.findIndex((item) => item.moodboardId === id);

    let responsiveWidth =
      (targetList[index].moodboardMeta.dimensions.width * 1000) / width;
    let responsiveHeight =
      (targetList[index].moodboardMeta.dimensions.height * 1000) / width;

    let newWidth =
      targetList[index].moodboardMeta.dimensions.width +
      (deltaX / responsiveWidth) * 200;
    let newHeight =
      targetList[index].moodboardMeta.dimensions.height +
      (deltaX / responsiveHeight) * 200;

    let newHeightWithOutAspectRatio =
      targetList[index].moodboardMeta.dimensions.height + deltaY;
    let newWidthWithOutAspectRatio =
      targetList[index].moodboardMeta.dimensions.width + deltaX;

    if (product.bkgImageURL || !product.moodboardMeta.aspectRatio) {
      targetList[index].moodboardMeta.dimensions.width =
        newWidthWithOutAspectRatio > MIN_DIMENSION
          ? newWidthWithOutAspectRatio
          : MIN_DIMENSION;
      targetList[index].moodboardMeta.dimensions.height =
        newHeightWithOutAspectRatio > MIN_DIMENSION
          ? newHeightWithOutAspectRatio
          : MIN_DIMENSION;
    } else {
      targetList[index].moodboardMeta.dimensions.width =
        newWidth > MIN_DIMENSION ? newWidth : MIN_DIMENSION;
      targetList[index].moodboardMeta.dimensions.height =
        newHeight > MIN_DIMENSION ? newHeight : MIN_DIMENSION;
    }

    // if (product.bkgImageURL || !product.moodboardMeta.aspectRatio) {
    //   targetList[index].moodboardMeta.dimensions.width =
    //     newBkgwidth > 100 ? newBkgwidth : 100;
    //   targetList[index].moodboardMeta.dimensions.height =
    //     newBkgHeight > 100 ? newBkgHeight : 100;
    // } else {
    //   targetList[index].moodboardMeta.dimensions.width =
    //     newWidth > 100 ? newWidth : 100;
    //   targetList[index].moodboardMeta.dimensions.height =
    //     newHeight > 100 ? newHeight : 100;
    // }

    let tmp = Object.assign({}, props.list);
    tmp[state.roomType][props.tabIndex].moodboard = targetList;

    props.onChange(tmp[state.roomType][props.tabIndex], state.roomType);
  };

  const setResizeMode = (id, isResizing) => {
    const targetList = selectedTab.moodboard;
    const index = targetList.findIndex((item) => item.moodboardId === id);
    targetList[index].isResizing = isResizing;
    let tmp = Object.assign({}, props.list);
    tmp[state.roomType][props.tabIndex].moodboard = targetList;

    props.onChange(tmp[state.roomType][props.tabIndex], state.roomType);
  };

  const handleAddText = () => {
    const textId = uuidv4();
    let tmp = Object.assign({}, state.list);
    tmp[state.roomType][props.tabIndex].text[textId] = {
      text: "Enter Text",
      textSize: "18",
      hex: "#00000",
      activeFontFamily: "Open Sans",
      position: { x: 0, y: 0 },
    };

    props.onChange(tmp[state.roomType][props.tabIndex], state.roomType);
  };

  const textPositionChange = (textID, position) => {
    let tmp = Object.assign({}, state.list);
    tmp[state.roomType][props.tabIndex].text[textID] = {
      ...tmp[state.roomType][props.tabIndex].text[textID],
      position: position,
    };

    props.onChange(tmp[state.roomType][props.tabIndex], state.roomType);
  };

  const removeText = (textID) => {
    let tmp = Object.assign({}, state.list);
    delete tmp[state.roomType][props.tabIndex].text[textID];
    props.onChange(tmp[state.roomType][props.tabIndex], state.roomType);
  };

  const onChangeText = (textID, textData) => {
    let tmp = Object.assign({}, state.list);
    tmp[state.roomType][props.tabIndex].text[textID] = {
      text: textData.text,
      textSize: textData.textSize,
      hex: textData.hex,
      activeFontFamily: textData.activeFontFamily,
      position: textData.position,
    };
    props.onChange(tmp[state.roomType][props.tabIndex], state.roomType);
  };

  const handleShowDetails = (item, action) => {
    if (action === "set") {
      props.setProductDetails(item);
    }
    if (action === "close") {
      props.setProductDetails(null);
    }
  };

  const handleShowAirtableDetails = (item, action) => {
    if (action === "set") {
      setProductDetail(item);
      setShowProductDetail(true);
    }
    if (action === "close") {
      setProductDetail(null);
      setShowProductDetail(false);
    }
  };

  const setImg = (index, itemId) => {
    const targetList = selectedTab.moodboard;
    const _index = targetList.findIndex((item) => item.moodboardId === itemId);
    targetList[_index].displayedImage = targetList[_index].imageURLs[index];
    let newTab = selectedTab;
    newTab.moodboard = targetList;
    props.onChange(newTab, state.roomType);
  };

  const setCropImg = async (imgSrc, itemId) => {
    let dims = await getMeta(imgSrc);
    const targetList = selectedTab.moodboard;
    const _index = targetList.findIndex((item) => item.moodboardId === itemId);
    targetList[_index].displayedImage = imgSrc;
    targetList[_index].moodboardMeta.dimensions = dims;
    let newTab = selectedTab;
    newTab.moodboard = targetList;
    props.onChange(newTab, state.roomType);
  };

  const getMeta = (url) => {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.addEventListener("load", function () {
        resolve({
          width: this.naturalWidth,
          height: this.naturalHeight,
        });
      });
      img.src = url;
    });
  };

  const setCroppingMode = (id, isCropping) => {
    const targetList = selectedTab.moodboard;
    const index = targetList.findIndex((item) => item.moodboardId === id);
    targetList[index].isCropping = isCropping;
    let tmp = Object.assign({}, props.list);
    tmp[state.roomType][props.tabIndex].moodboard = targetList;
    setState((state) =>
      produce(state, (st) => {
        st.list = tmp;
      })
    );
  };

  const onRemove = (item, room) => {
    let copyList = copyOfArrayOrObject(state.list);
    let newList = copyList[room][props.tabIndex].moodboard.filter((i) => {
      return i.moodboardId !== item.moodboardId;
    });
    copyList[room][props.tabIndex].moodboard = newList;
    props.onChange(copyList[room][props.tabIndex], state.roomType);
  };

  const bringForward = (item) => {
    const targetList = selectedTab.moodboard;
    const index = targetList.findIndex(
      (i) => i.moodboardId === item.moodboardId
    );
    const newList = [...targetList];
    newList[index] = {
      ...item,
      moodboardMeta: {
        position: {
          top: item.moodboardMeta.position.top,
          left: item.moodboardMeta.position.left,
          zIndex: item.moodboardMeta.position.zIndex + 1,
        },
        dimensions: {
          width: item.moodboardMeta.dimensions.width,
          height: item.moodboardMeta.dimensions.height,
        },
        rotation: item.moodboardMeta.rotation,
        flipImg: item.moodboardMeta.flipImg,
        aspectRatio: item.moodboardMeta.aspectRatio,
      },
    };
    let newTab = selectedTab;
    newTab.moodboard = newList;
    props.onChange(newTab, state.roomType);
  };
  const bringToFront = (item) => {
    const targetList = selectedTab.moodboard;
    const itemMaxZ = maxBy(
      targetList,
      (e) => {
        return e.moodboardMeta.position.zIndex;
      },
      "zIndex"
    );
    if (!itemMaxZ) {
      return;
    }
    if (
      itemMaxZ &&
      itemMaxZ.moodboardMeta.position.zIndex !==
        item.moodboardMeta.position.zIndex
    ) {
      const index = targetList.findIndex(
        (i) => i.moodboardId === item.moodboardId
      );
      const newList = [...targetList];
      newList[index] = {
        ...item,
        moodboardMeta: {
          position: {
            top: item.moodboardMeta.position.top,
            left: item.moodboardMeta.position.left,
            zIndex: itemMaxZ.moodboardMeta.position.zIndex + 1,
          },
          dimensions: {
            width: item.moodboardMeta.dimensions.width,
            height: item.moodboardMeta.dimensions.height,
          },
          rotation: item.moodboardMeta.rotation,
          flipImg: item.moodboardMeta.flipImg,
          aspectRatio: item.moodboardMeta.aspectRatio,
        },
      };
      let newTab = selectedTab;
      newTab.moodboard = newList;
      props.onChange(newTab, state.roomType);
    }
  };

  const sendBackward = (item) => {
    if (item.moodboardMeta.position.zIndex !== 0) {
      const targetList = selectedTab.moodboard;
      const newList = [...targetList];
      const index = targetList.findIndex(
        (i) => i.moodboardId === item.moodboardId
      );
      newList[index] = {
        ...item,
        moodboardMeta: {
          position: {
            top: item.moodboardMeta.position.top,
            left: item.moodboardMeta.position.left,
            zIndex: item.moodboardMeta.position.zIndex - 1,
          },
          dimensions: {
            width: item.moodboardMeta.dimensions.width,
            height: item.moodboardMeta.dimensions.height,
          },
          rotation: item.moodboardMeta.rotation,
          flipImg: item.moodboardMeta.flipImg,
          aspectRatio: item.moodboardMeta.aspectRatio,
        },
      };
      let newTab = selectedTab;
      newTab.moodboard = newList;
      props.onChange(newTab, state.roomType);
    }
  };
  const sendToBack = (item) => {
    if (item.moodboardMeta.position.zIndex !== 0) {
      const targetList = selectedTab.moodboard;
      const newList = [...targetList];
      const index = targetList.findIndex(
        (i) => i.moodboardId === item.moodboardId
      );
      newList[index] = {
        ...item,
        moodboardMeta: {
          position: {
            top: item.moodboardMeta.position.top,
            left: item.moodboardMeta.position.left,
            zIndex: 0,
          },
          dimensions: {
            width: item.moodboardMeta.dimensions.width,
            height: item.moodboardMeta.dimensions.height,
          },
          rotation: item.moodboardMeta.rotation,
          flipImg: item.moodboardMeta.flipImg,
          aspectRatio: item.moodboardMeta.aspectRatio,
        },
      };
      let newTab = selectedTab;
      newTab.moodboard = newList;
      props.onChange(newTab, state.roomType);
    }
  };

  const generatePdfDebounced = debounce(props.generatePdf, 1000);

  return (
    <React.Fragment>
      {selectedTab ? (
        <div
          className="canvas"
          style={{
            backgroundColor:
              (selectedTab.meta && selectedTab.meta.background) || "#ffffff",
            position: "relative",
          }}
          ref={canvasRef}
        >
          {selectedTab.moodboard.map((item, index) => {
            const dims = {
              width: item.moodboardMeta.dimensions.width,
              height: item.moodboardMeta.dimensions.height,
            };

            // const dims = {
            //   width: `calc(${item.moodboardMeta.dimensions.width}px) + ${
            //     item.moodboardMeta.dimensions.width / 2
            //   }vw`,
            //   height: `calc(${item.moodboardMeta.dimensions.height}px) + ${
            //     item.moodboardMeta.dimensions.height / 2
            //   }vw`,
            // };

            // const dims = {
            //   width: (item.moodboardMeta.dimensions.width * 1000) / width,
            //   height: (item.moodboardMeta.dimensions.height * 1000) / width,
            // };

            return (
              <>
                <Draggable
                  key={item.moodboardId}
                  defaultPosition={{
                    x: item.moodboardMeta.position.left,
                    y: item.moodboardMeta.position.top,
                  }}
                  disabled={item.isResizing || item.isCropping}
                  onStart={(evt) => handleStart(item, evt)}
                  onStop={(e, node) => {
                    handleStop(item, node);
                  }}
                  scale={props.scale}
                  bounds="parent"
                  style={{
                    ...dims,
                    position: "absolute",
                  }}
                >
                  <div className="item">
                    <div className="draggable-content">
                      <div
                        className={
                          item.isResizing
                            ? "fh-moodboard--canvas--item-quantity--hidden"
                            : "fh-moodboard--canvas--item-quantity"
                        }
                      >
                        {item.quantity && item.quantity > 1 && (
                          <div>x{item.quantity}</div>
                        )}
                      </div>
                      <ImageOnCanvas
                        style={dims}
                        showItemMenu={(show) => setShowItemMenu(!show)}
                        url={item.bkgImageURL || item.displayedImage}
                        alt=""
                        imgArray={item.imageURLs ? item.imageURLs : null}
                        setImg={(index, id) => setImg(index, id)}
                        item={item}
                        roomType={state.roomType}
                        removeBG={(item, roomType) =>
                          props.removeBG(item, roomType)
                        }
                        delete={(item, roomType) => onRemove(item, roomType)}
                        detailsModal={(item) => handleShowDetails(item, "set")}
                        airtableDetailsModal={(item) =>
                          handleShowAirtableDetails(item, "set")
                        }
                        setCropImg={(imgSrc, itemId) =>
                          setCropImg(imgSrc, itemId)
                        }
                        setCroppingMode={(id, isResizing) =>
                          setCroppingMode(id, isResizing)
                        }
                        list={state.list}
                        onListChange={(newList, roomType) => {
                          props.onChange(newList, roomType);
                        }}
                        tabIndex={props.tabIndex}
                        bringForward={() => bringForward(item)}
                        bringToFront={() => bringToFront(item)}
                        sendBackward={() => sendBackward(item)}
                        sendToBack={() => sendToBack(item)}
                        editProduct={props.editProduct}
                      />
                      <div
                        className={
                          item.isResizing
                            ? "fh-moodboard--canvas--item-options--hidden"
                            : "fh-moodboard--canvas--item-options"
                        }
                      >
                        {item.quantity && item.quantity > 1 && (
                          <div>x{item.quantity}</div>
                        )}
                        <Resizer
                          id={item.moodboardId}
                          item={item}
                          position={dims}
                          isResizing={item.isResizing}
                          setResizeMode={setResizeMode}
                          onResize={handleResizing}
                          onResizeStop={handleResizeStop}
                        />
                      </div>
                    </div>
                  </div>
                </Draggable>
              </>
            );
          })}

          {Object.keys(selectedTab.text).map((textID, index) => (
            <>
              <Text
                textID={textID}
                textData={selectedTab.text[textID]}
                tabIndex={props.tabIndex}
                onTextChange={onChangeText}
                removeText={removeText}
                disabled={false}
                textPositionChange={textPositionChange}
              />
            </>
          ))}
          {(showItemMenu || selectedTab.moodboard.length < 1) && (
            <CanvasMenu
              outerRef={canvasRef}
              handleBackgroundChange={() =>
                setShowBackgroundChange(!showBackgroundChange)
              }
              handleClearCanvas={() => props.clearCanvas(state.roomType)}
              handleAddText={handleAddText}
              generatePdf={() => generatePdfDebounced()}
            />
          )}
          {showBackgroundChange && (
            <PageModal
              visible={showBackgroundChange}
              component={
                <BackgroundChange
                  handleBKGChange={(color) =>
                    props.setBackground(color.hex, state.roomType)
                  }
                />
              }
              borderRadius="5px"
              padding="calc(20px + 2vw)"
              onClose={() => setShowBackgroundChange(false)}
              overLay="transparent"
            />
          )}
        </div>
      ) : (
        console.table("state.list", state.list, props.list, state.roomType)
      )}
    </React.Fragment>
  );
};

let mapStateToProps = (state) => {
  return {
    activeRoom: state.activeRoom,
    activeUnit: state.activeUnit,
  };
};
let Canvas = connect(mapStateToProps)(UnconnectedCanvas);
export default Canvas;
