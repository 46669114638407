import React, { useState, useEffect } from "react";
import InputBox from "./InputBox";
import copyOfArrayOrObject from "../../Functions/copyOfArrayOrObject";
import { Range } from "rc-slider";
import "rc-slider/assets/index.css";

const RangeSelector = (props) => {
  const [rangeValues, setRangeValues] = useState({ min: 0, max: 0 });

  useEffect(() => {
    const newRangeValues = { min: 0, max: 0 };
    newRangeValues.min = props.minRange;
    newRangeValues.max = props.maxRange;

    setRangeValues(newRangeValues);
  }, [props]);

  const style = {
    wrapper: {
      width: "100%",
    },
    label: {
      fontSize: props.labelFontSize,
      margin: props.labelMargin ? props.labelMargin : "0",
      padding: props.labelPadding ? props.labelPadding : "0",
      color: props.labelColor,
    },
    range: {
      width: "100%",
      padding: "calc(5px + 0.5vw) 0",
    },
    rangeLabel: {
      fontSize: "calc(3px + 0.3vw) ",
      fontWeight: "bold",
      margin: "0",
    },
    rangeBarSizeValues: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    inputRow: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
    },
  };

  const handleRangeInput = (event) => {
    const currentRangeValues = copyOfArrayOrObject(rangeValues);
    currentRangeValues[event.target.name] = event.target.value;
    setRangeValues(currentRangeValues);
    props.onChange(currentRangeValues);
  };

  const handleRangeSliderValue = (value) => {
    const newRangeValues = copyOfArrayOrObject(rangeValues);
    newRangeValues.min = value[0];
    newRangeValues.max = value[1];
    setRangeValues(newRangeValues);
    props.onChange(newRangeValues);
  };

  return (
    <div style={style.wrapper}>
      <h6 style={style.label}>{props.label}</h6>
      <div style={style.range}>
        <Range
          min={props.rangeSize.min}
          max={props.rangeSize.max}
          value={[rangeValues.min, rangeValues.max]}
          onChange={(v) => handleRangeSliderValue(v)}
        />
        <div style={style.rangeBarSizeValues}>
          <small style={style.rangeLabel}>{props.rangeSize.min}</small>
          <small style={style.rangeLabel}>{props.rangeSize.max}</small>
        </div>
      </div>
      <div style={style.inputRow}>
        <div style={{ width: "47%" }}>
          <InputBox
            name="min"
            type="number"
            background="#FFFFFF"
            border="1px solid #DCE1EB"
            activeBorder="1px solid #242627"
            borderRadius="5px"
            width="100%"
            height="calc(20px + 1vw)"
            padding="0 calc(5px + 0.5vw)"
            margin="0"
            fontSize="calc(8px + 0.5vw)"
            placeholder="Min"
            suffixText={props.rangeValueLabel}
            value={rangeValues.min}
            onChange={(e) => handleRangeInput(e)}
          />
        </div>
        <div style={{ width: "47%" }}>
          <InputBox
            name="max"
            type="number"
            background="#FFFFFF"
            border="1px solid #DCE1EB"
            activeBorder="1px solid #242627"
            borderRadius="5px"
            width="100%"
            height="calc(20px + 1vw)"
            padding="0 calc(5px + 0.5vw)"
            margin="0"
            fontSize="calc(8px + 0.5vw)"
            placeholder="Max"
            suffixText={props.rangeValueLabel}
            value={rangeValues.max}
            onChange={(e) => handleRangeInput(e)}
          />
        </div>
      </div>
    </div>
  );
};

export default RangeSelector;
