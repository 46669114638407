import React, { useState } from 'react';

let InputBox = (props) => {
  const [error, setError] = useState(false);
  const [active, setActive] = useState(false);

  let errorColor = props.validationErrorColor
    ? props.validationErrorColor
    : '#EA4B5F';
  let errorBorder = '1.5px solid ' + errorColor;

  let style = {
    inputWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContents: 'space-between',
      width: props.width ? props.width : '100%',
      padding: props.padding ? props.padding : '1px 2px',
      margin: props.margin,
      border: props.border,
      borderRadius: props.borderRadius ? props.borderRadius : '5px',
      background: props.background ? props.background : '#F7F7F7',
    },
    input: {
      width: '100%',
      border: 'none',
      height: props.rows ? null : props.height,
      margin: 0,
      padding: 0,
      color: props.color ? props.color : '#242627',
      fontSize: props.fontSize ? props.fontSize : '18px',
      textAlign: props.textAlign,
    },
    prefixText: {
      opacity: props.value ? 0.5 : 0.2,
      paddingRight: 'calc(2px + 0.2vw)',
      display: props.prefixText ? 'inherit' : 'none',
      fontSize: props.fontSize
        ? 'calc(' + props.fontSize + ' - 4px'
        : 'calc(5px + 0.5vw)',
    },
    suffixText: {
      opacity: props.value ? 0.5 : 0.2,
      paddingLeft: 'calc(2px + 0.2vw)',
      display: props.suffixText ? 'inherit' : 'none',
      fontSize: props.fontSize
        ? 'calc(' + props.fontSize + ' - 4px'
        : 'calc(5px + 0.5vw)',
    },
    error: {
      textAlign: 'left',
      margin: 0,
      padding: 0,
      fontSize: props.fontSize ? 'calc(' + props.fontSize + ' - 4px)' : '14px',
      color: props.validationErrorColor
        ? props.validationErrorColor
        : '#EA4B5F',
    },
  };

  let handleEnteredInput = (event) => {
    event.stopPropagation();
    props.onChange(event);
    setActive(true);
    setError(false);
  };

  let handleOnBlur = (event) => {
    setActive(false);
    props.onBlur && props.onBlur(event);
    let isValidInput = true;
    if (props.validation !== undefined) {
      isValidInput = props.validation;
    }
    setError(!isValidInput);
  };

  return props.rows || props.cols ? (
    <React.Fragment>
      <textarea
        style={{ ...style.input, ...style.inputWrapper }}
        defaultValue={props.defaultValue}
        value={props.value ? props.value : ''}
        placeholder={props.placeholder}
        type={props.type}
        min={props.min}
        name={props.name}
        onChange={handleEnteredInput}
        onBlur={handleOnBlur}
        autoComplete={props.autoComplete}
        autoFocus={props.autoFocus && props.autoFocus ? props.autoFocus : null}
        readOnly={props.readOnly && props.readOnly ? props.readOnly : null}
        rows={props.rows}
        cols={props.cols}
      />
      {error && (
        <p style={style.error}>
          {props.validationErrorMessage
            ? props.validationErrorMessage
            : 'error in input...'}
        </p>
      )}
    </React.Fragment>
  ) : (
    <React.Fragment>
      <div style={style.inputWrapper}>
        <div style={style.prefixText}>{props.prefixText}</div>
        <div style={{ flex: '1' }}>
          <input
            style={style.input}
            defaultValue={props.defaultValue}
            value={props.value ? props.value : ''}
            placeholder={props.placeholder}
            type={props.type}
            name={props.name}
            onChange={handleEnteredInput}
            onBlur={handleOnBlur}
            autoComplete={props.autoComplete}
            autoFocus={
              props.autoFocus && props.autoFocus ? props.autoFocus : null
            }
            readOnly={props.readOnly && props.readOnly ? props.readOnly : null}
          />
        </div>
        <div style={style.suffixText}>{props.suffixText}</div>
      </div>
      {error && (
        <p style={style.error}>
          {props.validationErrorMessage
            ? props.validationErrorMessage
            : 'error in input...'}
        </p>
      )}
    </React.Fragment>
  );
};

export default InputBox;
