import React, { useEffect, useState } from 'react';
import '../Products.css';
import Button from '../../../Common/Button';
import { connect } from 'react-redux';
import Gap from '../../../Common/Gap';
import numberToCurrencyFormatter from '../../../../Functions/numberToCurrencyFormatter';

const UnconnectedProductCard = (props) => {
  useEffect(() => {}, []);

  return (
    <div className='product-card-wrapper'>
      <div className='product-card-wrapper-container'>
        <div
          className='product-card-product-image'
          style={{ backgroundImage: 'url(' + props.product.imageURLs[0] + ')' }}
        >
          {!props.product.stockQty && (
            <div className='img__wrapper'>
              <a className='sold_out'>Out of stock</a>
            </div>
          )}
        </div>
        <div className='product-card-wrapper-container-overlay'>
          <div className='product-card-wrapper-container-overlay-top'>
            <h5>
              {props.product.itemName.length > 25
                ? props.product.itemName.substring(0, 25) + '...'
                : props.product.itemName}
            </h5>
            <h2>
              {numberToCurrencyFormatter(
                props.product.MAP,
                props.product.tradeCurrency
              )}
            </h2>
            <p>{props.product.dimension}</p>
          </div>
          <div className='product-card-wrapper-container-overlay-bottom'>
            <Button
              name='select'
              background='#ff4e24'
              borderRadius='5px'
              width='100%'
              enabled={true}
              height='calc(20px + 1vw)'
              padding='0'
              onClick={() =>
                props.handleSelectProduct(
                  props.product,
                  props.activeRoom.description
                )
              }
              color='#ffffff'
              label='Select'
              fontSize='calc(8px + 0.5vw)'
            />
            <Gap value='calc(2px + 0.2vw)' />
            <Button
              name='details'
              background='#242627'
              borderRadius='5px'
              width='100%'
              enabled={true}
              height='calc(20px + 1vw)'
              padding='0'
              onClick={() => props.detailsModal(props.product)}
              color='#ffffff'
              label='Details'
              fontSize='calc(8px + 0.5vw)'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

let mapStateToProps = (state) => {
  return {
    activeRoom: state.activeRoom,
  };
};
let ProductCard = connect(mapStateToProps)(UnconnectedProductCard);
export default ProductCard;
