import React, { useState, useEffect } from "react";
import "./ColorFilter.css";
import { ChromePicker } from "react-color";

const ColorFilter = (props) => {
  const [color, setColor] = useState("#ff4e24");

  useEffect(() => {
    if (props.color) {
      setColor(props.color);
    } else {
      setColor("#ff4e24");
    }
  }, [props]);

  const handleChangeComplete = (color) => {
    setColor(color.hex);
    props.handleColorSelect(color.hex);
  };

  return (
    <div className="color-filter-wrapper">
      <h6 className="color-filter-label">Choose Color</h6>
      <div className="color-filter-color-picker">
        <ChromePicker color={color} onChangeComplete={handleChangeComplete} />
      </div>
    </div>
  );
};

export default ColorFilter;
