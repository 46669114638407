import React from 'react';
import './DesignMenus.css';
import { FiFile } from 'react-icons/fi';
import { BsImages } from 'react-icons/bs';
import { BsLayoutWtf } from 'react-icons/bs';
import { FiEdit } from 'react-icons/fi';
import { v4 as uuidv4 } from 'uuid';
import { BsFillCaretLeftFill } from 'react-icons/bs';
import { FaFileInvoice } from 'react-icons/fa';

const DesignMenus = (props) => {
  const menus = [
    {
      title: 'Style',
      text:
        'View the design style for this unit. Also change style if required',
      icon: BsImages,
    },
    {
      title: 'Design Elements',
      text:
        'Select design elements like backgrounds, doors, windows, etc. You can also add new elements',
      icon: BsLayoutWtf,
    },
    {
      title: 'Project Documents',
      text:
        'View all documents associated with this project, you can also add new documents',
      icon: FiFile,
    },
    {
      title: 'Project Notes/Comments',
      text:
        'All additional notes about this project, you can also add new notes',
      icon: FiEdit,
    },
    {
      title: 'Go To Quotes',
      text: 'Open quote for current project.',
      icon: FaFileInvoice,
    },
  ];

  const handleMenuAction = (menuTitle) => {
    if (menuTitle === 'Style') {
      props.handleShowStyle();
    }

    if (menuTitle === 'Project Documents') {
      props.handleShowDocuments();
    }

    if (menuTitle === 'Project Notes/Comments') {
      props.handleShowNotes();
    }

    if (menuTitle === 'Design Elements') {
      props.handleShowDesignElements();
    }

    if (menuTitle === 'Go To Quotes') {
      props.handleGoToQuotes();
    }
  };

  return (
    <div className='design-menus-wrapper'>
      {menus.map((menu) => {
        return (
          <div key={uuidv4()} className='design-menus-menu-container'>
            <menu.icon
              className='design-menus-menu-icon'
              onClick={() => handleMenuAction(menu.title)}
            />
            <div className='design-menus-menu-tool-tip'>
              <BsFillCaretLeftFill className='design-menus-menu-tool-tip-caret' />
              <div className='design-menus-menu-description'>
                <h6>{menu.title}</h6>
                <p>{menu.text}</p>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default DesignMenus;
