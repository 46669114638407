const getCategoryCount = (moodboardData, unit, itemName, roomType) => {
  let selectedUnit = '';
  let count = 0;
  if (moodboardData && unit && moodboardData[unit.unit.fields['Item Name']]) {
    selectedUnit = unit.unit.fields['Item Name'];
    Object.keys(moodboardData[selectedUnit]).map((room, index) => {
      if (Array.isArray(moodboardData[selectedUnit][room])) {
        moodboardData[selectedUnit][room].map((tab, index) => {
          if (tab.activeTab === true) {
            moodboardData[selectedUnit][room][index].moodboard.map((item) => {
              if (
                ((item.fulhausCategory &&
                  item.fulhausCategory.trim() === itemName.trim()) ||
                  (item.mappedFulhausCategory &&
                    item.mappedFulhausCategory.trim() === itemName.trim())) &&
                roomType === room
              ) {
                count += 1;
              }
              return count;
            });
          }
        });
      }
    });
    return count;
  }
};

// moodboardData[selectedUnit][room].moodboard.map((item) => {
//   if (
//     item.fulhausCategory === itemName ||
//     (item.mappedFulhausCategory === itemName && roomType === room)
//   ) {
//     count += 1;
//   }
//   return count;
// })
// );
const getRoomItemCount = (moodboardData, unit, roomType) => {
  let count = 0;
  unit.quoteItems.forEach((roomData) => {
    if (roomData.room.description === roomType) {
      roomData.items.forEach((item) => {
        count += getCategoryCount(
          moodboardData,
          unit,
          item.fields['Item Name'],
          roomType
        );
      });
    }
  });
  return count;
};

const getTotalQtyPerRoom = (unit, roomType) => {
  let count = 0;
  unit.quoteItems.forEach((roomData) => {
    if (roomData.room.description === roomType) {
      roomData.items.forEach((item) => {
        count += item.fields[unit.unit.fields.SKU];
      });
    }
  });
  return count;
};

const getUnitItemCount = (quoteData, moodboardData, unitName) => {
  let count = 0;
  quoteData.forEach((unitData) => {
    if (unitData.unit.fields['Item Name'] === unitName) {
      unitData.quoteItems.forEach((roomData) => {
        count += getRoomItemCount(
          moodboardData,
          unitData,
          roomData.room.description
        );
      });
    }
  });
  return count;
};

const getTotalQtyPerUnit = (quoteData, unitName) => {
  let count = 0;
  quoteData.forEach((unitData) => {
    if (unitData.unit.fields['Item Name'] === unitName) {
      unitData.quoteItems.forEach((roomData) => {
        count += getTotalQtyPerRoom(unitData, roomData.room.description);
      });
    }
  });
  return count;
};

export {
  getCategoryCount,
  getRoomItemCount,
  getTotalQtyPerRoom,
  getTotalQtyPerUnit,
  getUnitItemCount,
};
