import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import data from '../../../../Data';
import { getUnitTotal, getProjectTotal } from '../../../../Functions/totalFn';
import './StatsDropDown.css';

const UnconnectedStatsDropDown = (props) => {
  const [projectTotal, setProjectTotal] = useState(0);
  const [unitTotal, setUnitTotal] = useState(0);
  // const projectCurrency = data.project.Currency;
   const projectCurrency = data.project.CurrencyIsoCode;

  useEffect(() => {
    setUnitTotal(getUnitTotal(props.moodboardData, props.activeUnit));

    setProjectTotal(getProjectTotal(props.moodboardData));
  }, []);

  return (
    <div className='statsDropDown-container'>
      <div className='project-cost'>
        <div>
          <h3 className='highlight'>Project Budget</h3>
          <h3>${data.quote.budget.toFixed(2)}</h3>
        </div>
        <div>
          <h3>Items used</h3>
          <h3>${projectTotal.toFixed(2)}</h3>
        </div>
        <div
          style={{
            borderTop: '0.5px solid #F2F2F2',
            marginTop: '8px',
            padding: '8px 0',
          }}
        >
          <h3>Amount left:</h3>
          <h3>${(data.quote.budget - projectTotal).toFixed(2)}</h3>
        </div>
      </div>
      <div className='project-cost'>
        <div>
          <h3 className='highlight'>Unit Budget</h3>
          <h3>
            $
            {props.activeUnit.unit.fields[`Price (${projectCurrency})`].toFixed(
              2
            )}
          </h3>
        </div>
        <div>
          <h3>Items used</h3>
          <h3>${unitTotal.toFixed(2)}</h3>
        </div>
        <div
          style={{
            borderTop: '0.5px solid #F2F2F2',
            marginTop: '8px',
            padding: '8px 0',
          }}
        >
          <h3>Amount left:</h3>
          <h3>
            $
            {(
              props.activeUnit.unit.fields[`Price (${projectCurrency})`] -
              unitTotal
            ).toFixed(2)}
          </h3>
        </div>
      </div>
    </div>
  );
};

let mapStateToProps = (state) => {
  return {
    activeRoom: state.activeRoom,
    activeUnit: state.activeUnit,
    moodboardData: state.moodboardData,
  };
};
let StatsDropDown = connect(mapStateToProps)(UnconnectedStatsDropDown);
export default StatsDropDown;
